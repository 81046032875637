import React from "react";

import { Avatar } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  avatar: {
    fontSize: "14px !important",
    backgroundColor: `${theme.palette.primary.light} !important`,
    color: `${theme.palette.common.white} !important`,
  },
}));

export const nameShorten = (name) => {
  var splittedName = [""];
  if (name) {
    splittedName = name.trim().split(",")[0].split(" ");
  }
  return splittedName?.length > 1
    ? `${splittedName[0][0]}${splittedName[splittedName.length - 1][0]}`
    : splittedName?.[0]?.length > 0
    ? `${splittedName[0][0]}`
    : "";
};

function CustomAvatar(props) {
  const classes = useStyles();
  // const dispatch = useDispatch();

  return (
    <Avatar
      {...props}
      src={props.src}
      className={`${classes.avatar} ${props?.className}`}
      sx={props.sx && props.sx}
    >
      {nameShorten(props.name)}
    </Avatar>
  );
}

export default CustomAvatar;
