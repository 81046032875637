import { createSlice } from "@reduxjs/toolkit";

export const basicReducer = createSlice({
  name: "basicReducer",
  initialState: {
    users: [],
    selectedEmp: null,
    anchorEl: null,
  },
  reducers: {
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    setSelectedEmp: (state, action) => {
      state.selectedEmp = action.payload;
    },
    setAnchorEl: (state, action) => {
      state.anchorEl = action.payload;
    },
  },
});

export const { setUsers, setSelectedEmp, setAnchorEl } = basicReducer.actions;

export default basicReducer.reducer;
