import React from "react";

import { makeStyles } from "@mui/styles";

import { BiBell } from "react-icons/bi";
import { IoSettingsOutline } from "react-icons/io5";
import { BsSearch } from "react-icons/bs";

import { useDispatch, useSelector } from "react-redux";

import CustomAvatar from "./CustomAvatar";

const useStyle = makeStyles((theme) => ({
  headerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "sticky",
    top: 0,
    zIndex: 999,
    backgroundColor: `${theme.palette.background.paper} !important`,
    paddingRight: 12,
    boxShadow: "0px 2px 4px rgba(192, 192, 192, 0.25)",
  },
  headerContainerLogo: {
    marginLeft: `${theme.spacing(2.5)} !important`,
  },
  headerRightContainer: {
    display: "flex",
    alignItems: "center",
  },
  headerInputContainer: {
    margin: 8,
    display: "flex",
    alignItems: "center",
    border: "1px solid #bdbdbd",
    borderRadius: 4,
    padding: 4,
    backgroundColor: "#F1F5FE",
  },
  headerInput: {
    border: "0px solid",
    outlined: "none",
    backgroundColor: "#F1F5FE",
  },
  headerIconContainer: {
    margin: 8,
    cursor: "pointer",
  },
}));

function Header({ openSignOut, setOpenSignOut }) {
  const classes = useStyle();
  const userReducerState = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();

  const resetAll = () => {
    setOpenSignOut(false);
  };
  return (
    <div className={classes.headerContainer}>
      <img
        src="/IIM_Logo.png"
        alt="WorkAccess_Logo"
        className={classes.headerContainerLogo}
      />

      <div className={classes.headerRightContainer}>
        <div className={classes.headerInputContainer}>
          <input placeholder="Search" className={classes.headerInput} />

          <BsSearch style={{ fontSize: 20 }} />
        </div>

        <div className={classes.headerIconContainer}>
          <BiBell
            style={{ fontSize: 20 }}
            onClick={() => {
              // resetAll();
            }}
          />
        </div>

        <div className={classes.headerIconContainer}>
          <IoSettingsOutline
            style={{ fontSize: 20 }}
            onClick={() => {
              // resetAll();
            }}
          />
        </div>

        <div className={classes.headerIconContainer}>
          <CustomAvatar
            name={userReducerState?.user?.name || ""}
            onClick={(e) => {
              e.stopPropagation();
              resetAll();
              setOpenSignOut(!openSignOut);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Header;
