import React, { useState, useEffect } from "react";

import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";

import { useDispatch } from "react-redux";

import WorkOrg from "./WorkOrg";
import Loading from "./Components/Loading";

import { setToken, setUser } from "./Redux/Reducers/userReducer";
import { theme } from "./Utility/theme";

function App({ keycloak }) {
  const [load, setLoad] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    saveBasicDetail();
  }, []);

  const saveBasicDetail = () => {
    dispatch(setToken(keycloak.token));
    dispatch(setUser(keycloak.tokenParsed));
  };

  return (
    <ThemeProvider theme={theme}>
      <Router>
        {load ? <Loading load={load} /> : <WorkOrg keycloak={keycloak} />}
      </Router>
    </ThemeProvider>
  );
}

export default App;
