import { createTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const theme = createTheme({
  spacing: (factor) => `${0.5 * factor}rem`,
  palette: {
    primary: {
      main: "rgba(48, 38, 185, 1)", //application primary theme colour
      dark: "rgba(234, 233, 255, 1)", //lighter version of the application theme color
      light: "#F1F5FE", //lighter version of the application theme color
      secondary: "#E5E5E5", //Background color of the page
    },
    background: {
      default: "#FAFCFF",
      paper: "#FFFFFF",
      navbar: "#FFFFFF",
      accordionLight: "#F9FBFE",
    },
    common: {
      white: "#FFFFFF",
      black: "#1D1D11",
    },
    secondary: {
      main: "#CB4747", //secondary brand colour ---- Cherrywork - red
      light: "#ea9493", //lighter brand colour ---- Cherrywork - red
      complementary: "#649b45", //lighter brand colour ---- Cherrywork - green
    },
    action: {
      main: "#DBE9FF",
    },
    itmColors: {
      open: "#002D5E", //for state open/ pending
      inprogress: "#CD722C", //for state in progress or WIP
      approved: "#4CAF50", //for state closed or completed
      rejected: "#FF0101", //for state closed or completed
      hold: "#CD722C", //for state on hold or not started yet
      onTime: "#26A933", //for sla on time
      critical: "#FFDF8C", //for critical
      breached: "#FF0101", //for sla breached
    },
    button: {
      createTask: "#DBE9FF", //Create Task Button background colour
    },
    text: {
      primary: "#1D1D11",
      secondary: "#999A96", //For Pinned Task toggle - Grey colour
      black: "#3F3F3F",
      darkGrey: "#5E5E5E",
      lightGrey: "#F2F2F2",
      midGrey: "#757575",
    },
  },
  props: {
    // Name of the component
    MuiButtonBase: {
      // The properties to apply
      disableRipple: true, // No more ripple, on the whole application!
    },
  },

  shape: {
    borderRadius: 4,
  },
  typography: {
    button: {
      textTransform: "none",
    },
    h1: {
      fontSize: "1.25rem !important", //20
      fontWeight: "normal",
    },
    h2: {
      fontSize: "1.125rem !important", //18
      fontWeight: "normal",
    },
    h3: {
      fontSize: "1rem !important", //16
      fontWeight: "normal",
    },
    h4: {
      fontSize: "0.875rem !important", //14
      fontWeight: "normal",
    },
    h5: {
      fontSize: "0.75rem !important", //12
      fontWeight: "normal",
      lineHeight: "14px",
    },
    h6: {
      fontSize: "22px !important", //10
      fontWeight: "normal",
    },
  },
});

export const useCustomStyles = makeStyles((theme) => {
  return {
    customButton: {
      fontWeight: "400 !important",
      boxShadow: "none !important",
      "& .MuiButton-sizeSmall": {
        height: `${theme.spacing(4)} !important`,
        fontSize: "14 !important",
      },
      "& .MuiButton-sizeMedium": {
        height: theme.spacing(4.5),
        fontSize: 14,
      },
      "& .MuiButton-sizeLarge": {
        height: theme.spacing(5),
        fontSize: 16,
      },
      "&.MuiButton-containedSecondary": {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.dark,
        "&:hover": {
          backgroundColor: theme.palette.primary.dark,
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        },
      },
      "&.MuiButton-containedPrimary": {
        "&:hover": {
          backgroundColor: theme.palette.primary.main,
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        },
      },
      "&.MuiButton-containedAction": {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.action.main,
        "&:hover": {
          backgroundColor: theme.palette.action.main,
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        },
      },
    },
    customAutoComplete: {
      "& .MuiAutocomplete-option, .MuiAutocomplete-popper": {
        zIndex: 4,
      },
      "& .MuiOutlinedInput-input": {
        zIndex: 2,
        padding: `${theme.spacing(0, 0, 0, 1)} !important`,
      },
      "& .MuiInputBase-sizeSmall": {
        height: theme.spacing(4),
        fontSize: 14,
      },
      "& .MuiInputBase-sizeMedium": {
        height: theme.spacing(5),
        fontSize: 14,
      },
      "& .MuiInputBase-sizeLarge": {
        height: theme.spacing(6),
        fontSize: 16,
      },
      "& .MuiAutocomplete-endAdornment": {
        zIndex: 2,
        "& .MuiIconButton-root": {
          background: "transparent",
          color: "#495057",
        },
      },
      "&. MuiOutlinedInput-notchedOutline": {
        zIndex: "1",
        border: "1px solid #ced4da",
        "& legend": {
          width: "0%",
        },
      },
    },
    option: {
      "& .MuiAutocomplete-option, .MuiAutocomplete-popper": {
        zIndex: 4,
      },
      zIndex: 4,
    },
  };
});
