import React, { useState } from "react";

import { makeStyles } from "@mui/styles";

import { Routes, Route } from "react-router-dom";

import Headers from "./Components/Header";
import EmpDetail from "./Components/EmployeeDetail/EmpDetail";
import Emp from "./Components/EmployeeDetail/Emp";
import SignOut from "./Components/SignOut";

import MasterData from "./Components/MasterData/index";
import EmpProfile from "./Components/EmpProfile/index";
import Sidebar from "./Components/Sidebar";

const useStyle = makeStyles((theme) => ({
  appContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.background.paper,
  },
  appBody: {
    width: "100%",
    height: "calc(100vh - 48px)",
    display: "flex",
    overflow: "hidden",
    position: "relative",
  },
}));

function WorkOrg({ keycloak }) {
  const classes = useStyle();
  const [openSignOut, setOpenSignOut] = useState(false);
  const [selectedSubItem, setSelectedSubItem] = useState("System");

  return (
    <div
      className={classes.appContainer}
      onClick={(e) => {
        setOpenSignOut(false);
      }}
    >
      {/* <Router> */}
      <Headers openSignOut={openSignOut} setOpenSignOut={setOpenSignOut} />
      {openSignOut && <SignOut keycloak={keycloak} />}

      <div className={classes.appBody}>
        <Sidebar
          selectedSubItem={selectedSubItem}
          setSelectedSubItem={setSelectedSubItem}
        />

        <Routes>
          <Route exact path="/" element={<Emp />} />

          <Route
            exact
            path="/masterData"
            element={<MasterData selectedSubItem={selectedSubItem} />}
          />

          <Route
            exact
            path="/profile"
            element={<EmpProfile selectedSubItem={selectedSubItem} />}
          />

          {/* <Route path="/:userId" element={<EmpDetail />} /> */}
        </Routes>
      </div>
      {/* </Router> */}
    </div>
  );
}

export default WorkOrg;
