import React, { useRef, useState, useEffect } from "react";

import {
  Grid,
  Button,
  Typography,
  Divider,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
// import Masonry from "@mui/lab/Masonry";

import { BsSearch, BsPersonBadge } from "react-icons/bs";
import {
  IoMdArrowBack,
  // IoPersonCircleOutline,
} from "react-icons/io";
import { IoLocationOutline, IoPersonCircleOutline } from "react-icons/io5";
import { MdOutlineCall } from "react-icons/md";
import {
  AiOutlineMail,
  AiOutlineFullscreen,
  AiOutlineFullscreenExit,
} from "react-icons/ai";
import { GoCalendar } from "react-icons/go";

// import { Tree, TreeNode } from "react-organizational-chart";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import WorkTip from "@cw/cherrywork-worktip";

import CustomAvatar from "../CustomAvatar";
import Loading from "../Loading";

import { setAnchorEl, setSelectedEmp } from "../../Redux/Reducers/basicReducer";

const useStyle = makeStyles((theme) => ({
  fsOrganizationViewDialogTitle: {
    height: "3rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderBottom: "1px solid #d9d9d9",
    margin: 0,
    padding: 0,
    paddingLeft: 10,
    backgroundColor: "#FAFCFF",
    boxShadow: "0px 2px 10px rgba(192, 192, 192, 0.25)",
    "& h2": {
      fontSize: 18,
      fontWeight: 500,
    },
  },
  fsOrganizationViewDialogActions: {
    height: "3rem",
    borderTop: "1px solid #d9d9d9",
    boxShadow: "0px -4px 9px #D8D8D8",
    paddingRight: 20,
  },

  headerInputContainer: {
    margin: 8,
    display: "flex",
    alignItems: "center",
    border: "1px solid #bdbdbd",
    borderRadius: 4,
    padding: 4,
    backgroundColor: "#ffffff",
  },
  headerInput: {
    border: "0px solid",
    outlined: "none",
    backgroundColor: "#ffffff",
  },
  avatar: {
    width: "132px !important",
    height: "132px !important",
    margin: "16px auto",
    fontSize: "70px !important",
  },
}));

// const OrganizationEmpCard = ({ data }) => {
//   {
//     /*
// 	style={{margin:"auto", border:"1px solid", borderRadius:20, width:250, padding:4}}
// 	*/
//   }
//   return (
//     <TreeNode
//       label={
//         <div>
//           <CustomAvatar
//             name={`${data?.firstName} ${data?.lastName}`}
//             src={data?.imageType && data?.profilePic ? `data:${data?.imageType};base64,${data?.profilePic}`:null}
//             style={{ margin: "4px auto" }}
//           />

//           <Typography
//             style={{
//               fontSize: 14,
//               whiteSpace: "nowrap",
//               overflow: "hidden",
//               textOverflow: "ellipsis",
//             }}
//           >
//             {`${data?.firstName} ${data?.lastName}`}
//           </Typography>

//           <Typography
//             style={{
//               fontSize: 14,
//               fontWeight: 300,
//               whiteSpace: "nowrap",
//               overflow: "hidden",
//               textOverflow: "ellipsis",
//             }}
//           >
//             {data?.designation}
//           </Typography>
//         </div>
//       }
//     >
//       {data?.children?.map((child, index) => (
//         <OrganizationEmpCard key={index} data={child} />
//       ))}
//     </TreeNode>
//   );
// };

const Organization = ({ org }) => {
  const [showCard, setShowCard] = useState(false);
  const basicReducerState = useSelector((state) => state.basicReducer);
  const userReducerState = useSelector((state) => state.userReducer);
  const id = Boolean(basicReducerState?.anchorEl)
    ? `workTip-${
        basicReducerState?.selectedEmp ? basicReducerState?.selectedEmp : ""
      }`
    : undefined;
  const dispatch = useDispatch();

  const card = (data) => {
    return (
      <div>
        <div
          style={{ width: "43px", borderRadius: "50%", margin: "auto" }}
          onMouseLeave={(e) => {
            e.stopPropagation();
            dispatch(setAnchorEl(null));
          }}
        >
          <CustomAvatar
            name={`${data?.firstName} ${data?.lastName}`}
            src={
              data?.imageType && data?.profilePic
                ? `data:${data?.imageType};base64,${data?.profilePic}`
                : null
            }
            aria-describedby={`workTip-${data?.employeeId}`}
            id={`workTip-${data?.employeeId}`}
            style={{ margin: "4px auto", cursor: "pointer" }}
            onMouseEnter={(e) => {
              e.stopPropagation();
              setShowCard(true);
              dispatch(setAnchorEl(e.currentTarget));
              dispatch(setSelectedEmp(data?.employeeId));
            }}
          />
          {basicReducerState?.selectedEmp === data?.employeeId && showCard && (
            <WorkTip
              id={id}
              anchorEl={basicReducerState?.anchorEl}
              onClose={() => {
                dispatch(setAnchorEl(null));
              }}
              placement="right-start"
              userId={data?.employeeId}
              token={userReducerState?.token}
            />
          )}
        </div>

        <Typography
          style={{
            fontSize: 14,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            textAlign: "center",
          }}
        >
          {`${data?.firstName} ${data?.lastName}`}
        </Typography>

        <Typography
          style={{
            fontSize: 12,
            fontWeight: "lighter",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            textAlign: "center",
          }}
        >
          {data?.designation}
        </Typography>
      </div>
    );
  };

  // return (
  //   <Tree
  //     label={
  //       <>
  //         <CustomAvatar
  //           name={`${org?.firstName} ${org?.lastName}`}
  // src={org?.imageType && org?.profilePic ? `data:${org?.imageType};base64,${org?.profilePic}` : null}
  //           style={{ margin: "4px auto" }}
  //         />

  //         <Typography
  //           style={{
  //             fontSize: 14,
  //             whiteSpace: "nowrap",
  //             overflow: "hidden",
  //             textOverflow: "ellipsis",
  //           }}
  //         >
  //           {`${org?.firstName} ${org?.lastName}`}
  //         </Typography>

  //         <Typography
  //           style={{
  //             fontSize: 12,
  //             fontWeight: "lighter",
  //             whiteSpace: "nowrap",
  //             overflow: "hidden",
  //             textOverflow: "ellipsis",
  //           }}
  //         >
  //           {org?.designation}
  //         </Typography>
  //       </>
  //     }
  //   >
  //     {org?.children?.map((child, index) => (
  //       <OrganizationEmpCard key={index} data={child} />
  //     ))}
  //   </Tree>
  // );

  if (!org) {
    return null;
  }
  return (
    <>
      {org?.highLvlData
        ?.slice(1)
        ?.reverse()
        ?.map((emp, index) => (
          <div key={index}>
            {card(emp)}

            <div
              style={{
                height: 24,
                margin: "auto",
                width: 2,
                backgroundColor: "black",
              }}
            />
          </div>
        ))}
      {org?.highLvlData?.length > 1 && org?.teamLvlData?.length === 1 && (
        <div
          style={{
            height: 24,
            margin: "auto",
            width: 2,
            backgroundColor: "black",
          }}
        />
      )}
      {org?.teamLvlData?.length > 1 && (
        <div
          style={{
            width: "100%",
            margin: "auto",
            height: 2,
            backgroundColor: "black",
          }}
        />
      )}
      {/* {org?.teamLvlData?.length > 1 ? ( */}
      <Grid container>
        {org?.teamLvlData?.map((emp, index) => (
          <Grid
            key={index}
            item
            xs={
              org?.teamLvlData?.length === 1
                ? 12
                : org?.teamLvlData?.length === 2
                ? 6
                : 4
            }
            align="center"
          >
            {card(emp)}
          </Grid>
        ))}
      </Grid>
      {/* // )
      //  : (
      //   <p style={{ textaAlign: "center" }}>{org?.teamLvlData?.[0]}</p>
      // )} */}
      {org?.teamLvlData?.length === 1 && (
        <>
          <div
            style={{
              width: "100%",
              margin: "auto",
              height: 2,
              backgroundColor: "black",
            }}
          />

          <Grid container>
            {org?.lowerLvlData?.map((emp) => (
              <Grid item xs={4} align="center">
                {card(emp)}
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </>
  );
};

const FullScreenOrganization = ({ open, onClose, employeeDetail }) => {
  const classes = useStyle();

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <DialogTitle className={classes.fsOrganizationViewDialogTitle}>
        Organization
      </DialogTitle>

      <DialogContent className="woScroll">
        <Organization org={employeeDetail?.organization} />
      </DialogContent>

      <DialogActions className={classes.fsOrganizationViewDialogActions}>
        <Button
          color="primary"
          variant="outlined"
          size="small"
          onClick={onClose}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DetailEntry = ({
  label,
  value,
  last,
  valueStyle,
  ValueIcon,
  onClick,
}) => {
  return (
    <Grid
      container
      style={{
        padding: "8px 16px",
        borderBottom: last ? "" : "1px solid #E5E5E5",
      }}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      <Grid
        item
        xs={6}
        style={{ padding: "0px 8px", display: "flex", alignItems: "center" }}
      >
        <Typography
          style={{
            fontSize: 12,
            fontWeight: "lighter",
            color: "#666666",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {label}
        </Typography>
      </Grid>

      <Grid
        item
        xs={6}
        style={{
          padding: "0px 8px",
          display: "flex",
          alignItems: "center",
          cursor: onClick ? "pointer" : "default",
        }}
      >
        {ValueIcon && ValueIcon}

        <Typography
          style={{
            fontSize: 12,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            ...valueStyle,
          }}
        >
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
};

const TeamGroup = ({ groupName, groupMembers }) => {
  return (
    <Paper
      elevation={0}
      style={{
        boxShadow: "0px 0px 4px rgba(119, 119, 119, 0.25)",
        paddingBottom: 2,
        height: "60vh",
      }}
      className="woScroll"
    >
      <Typography
        style={{
          backgroundColor: "#F9FAFD",
          padding: 14,
          fontSize: 16,
          fontWeight: "bold",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {groupName}
      </Typography>

      {groupMembers?.map((member, index) => (
        <TeamMember
          key={index}
          name={`${member?.firstName} ${member?.lastName}`}
          designation={member?.designation}
          imageType={member?.imageType}
          profilePic={member?.profilePic}
          employeeId={member?.employeeId}
        />
      ))}
    </Paper>
  );
};

const TeamMember = ({
  name,
  designation,
  imageType,
  profilePic,
  employeeId,
}) => {
  const [showCard, setShowCard] = useState(false);
  const basicReducerState = useSelector((state) => state.basicReducer);
  const userReducerState = useSelector((state) => state.userReducer);
  const id = Boolean(basicReducerState?.anchorEl)
    ? `workTip-${
        basicReducerState?.selectedEmp ? basicReducerState?.selectedEmp : ""
      }`
    : undefined;
  const dispatch = useDispatch();

  return (
    <Paper
      elevation={0}
      style={{
        boxShadow:
          "0px 0px 16px rgba(207, 207, 207, 0.25), 0px 0px 8px rgba(255, 252, 252, 0.25), 0px 0px 4px rgba(249, 249, 249, 0.25), 0px 0px 2px #E0E0E0",
        borderRadius: 4,
        border: "1px solid #F1F5FE",
        display: "flex",
        alignItems: "center",
        padding: 8,
        margin: 8,
      }}
    >
      <div
        style={{ width: "43px", borderRadius: "50%", margin: "auto" }}
        onMouseLeave={(e) => {
          e.stopPropagation();
          dispatch(setAnchorEl(null));
        }}
      >
        <CustomAvatar
          name={name}
          src={
            imageType && profilePic
              ? `data:${imageType};base64,${profilePic}`
              : null
          }
          onMouseEnter={(e) => {
            e.stopPropagation();
            setShowCard(true);
            dispatch(setAnchorEl(e.currentTarget));
            dispatch(setSelectedEmp(employeeId));
          }}
        />

        {basicReducerState?.selectedEmp === employeeId && showCard && (
          <WorkTip
            id={id}
            anchorEl={basicReducerState?.anchorEl}
            onClose={() => {
              dispatch(setAnchorEl(null));
            }}
            placement="left"
            userId={employeeId}
            token={userReducerState?.token}
          />
        )}
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          paddingLeft: 8,
          maxWidth: "80%",
        }}
      >
        <Typography
          style={{
            fontSize: 14,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {name}
        </Typography>

        <Typography
          style={{
            fontSize: 12,
            fontWeight: 300,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {designation}
        </Typography>
      </div>
    </Paper>
  );
};

const Team = ({ team }) => {
  const classes = useStyle();
  const [filteredTeam, setFilteredTeam] = useState(team);
  const [searchTeam, setSearchTeam] = useState("");

  const onSearchTeam = () => {
    setFilteredTeam({
      ...team,
      teamMembers: team?.teamMembers?.filter(
        (member) =>
          member?.firstName
            ?.toLowerCase()
            ?.includes(searchTeam?.toLowerCase()) ||
          member?.lastName
            ?.toLowerCase()
            ?.includes(searchTeam?.toLowerCase()) ||
          member?.designation
            ?.toLowerCase()
            ?.includes(searchTeam?.toLowerCase()) ||
          `${member?.firstName} ${member?.lastName}`
            ?.toLowerCase()
            ?.includes(searchTeam?.toLowerCase())
      ),
    });
  };
  useEffect(() => {
    if (searchTeam?.length > 0) {
      onSearchTeam();
    } else {
      setFilteredTeam({ ...team });
    }
  }, [searchTeam]);

  return (
    <div style={{ padding: 16 }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: 8,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            paddingRight: 24,
          }}
        >
          <Typography style={{ fontSize: 10, color: "#666666" }}>
            Name
          </Typography>

          <Typography style={{ fontSize: 14, color: "#5f5f5f" }}>
            {filteredTeam?.projectName}
          </Typography>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            paddingRight: 24,
          }}
        >
          <Typography style={{ fontSize: 10, color: "#666666" }}>
            Owner
          </Typography>

          <Typography style={{ fontSize: 14, color: "#5f5f5f" }}>
            {filteredTeam?.projectManagerName}
          </Typography>
        </div>
      </div>

      <Divider />

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography>Members ({filteredTeam?.teamMembers?.length})</Typography>

        <div className={classes.headerInputContainer}>
          <input
            placeholder="Search"
            className={classes.headerInput}
            value={searchTeam}
            onChange={(e) => setSearchTeam(e.target.value)}
          />

          <BsSearch style={{ fontSize: 20 }} />
        </div>
      </div>

      <Grid container spacing={3}>
        <Grid item xs={4}>
          <TeamGroup
            groupName="Project Execution"
            groupMembers={filteredTeam?.teamMembers}
          />
        </Grid>

        <Grid item xs={4}>
          <TeamGroup groupName="Development" groupMembers={[]} />
        </Grid>

        <Grid item xs={4}>
          <TeamGroup groupName="Design" groupMembers={[]} />
        </Grid>
      </Grid>
    </div>
  );
};

function EmpDetail() {
  const classes = useStyle();
  const userReducerState = useSelector((state) => state.userReducer);
  const ref = useRef(null);
  const [selectedTab, setSelectedTab] = useState("profile");
  const [employeeDetail, setEmployeeDetail] = useState(null);
  const [load, setLoad] = useState(false);
  const [fullScreenOrganizationView, setFullScreenOrganizationView] =
    useState(false);
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();

  ref?.current?.addEventListener("scroll", (e) => {
    if (inScreen("profile")) {
      setSelectedTab("profile");
    } else if (inScreen("organization")) {
      setSelectedTab("organization");
    } else if (inScreen("team")) {
      setSelectedTab("team");
    }
  });

  const inScreen = (id) => {
    var myElement = document.getElementById(id);
    if (!myElement) {
      return false;
    }
    var bounding = myElement.getBoundingClientRect();
    if (bounding.top <= 190 && bounding.bottom > 190) {
      return true;
    }
    return false;
  };
  const formateOrganizationView = (orgDet, userId) => {
    var org = {},
      child = [];
    if (orgDet?.data?.highLvlData?.length === 1) {
      child = orgDet?.data?.teamLvlData?.map((emp) =>
        emp?.employeeId === userId
          ? {
              ...emp,
              children: [...orgDet?.data?.lowerLvlData],
            }
          : { ...emp, children: [] }
      );
    }
    if (orgDet?.data?.highLvlData?.length >= 2) {
      child = [
        {
          ...orgDet?.data?.highLvlData?.[1],
          children: orgDet?.data?.teamLvlData?.map((emp) =>
            emp?.employeeId === userId
              ? {
                  ...emp,
                  children: [...orgDet?.data?.lowerLvlData],
                }
              : { ...emp, children: [] }
          ),
        },
      ];
    }
    for (var i = 2; i < orgDet?.data?.highLvlData?.length; i++) {
      org = {
        ...orgDet?.data?.highLvlData?.[i],
        children: [...child],
      };
      child = [org];
      org = {};
    }
    return child?.[0] || {};
  };
  useEffect(() => {
    if (params?.userId && userReducerState?.token) {
      setLoad(true);
      Promise.all([
        fetch(
          `${process.env.REACT_APP_WorkOrgServices}/api/v1/sf/employeeDetails?employeeId=${params?.userId}`,
          {
            headers: {
              Authorization: `Bearer ${userReducerState?.token}`,
            },
          }
        ).then((res) => res.json()),
        fetch(
          `${process.env.REACT_APP_WorkOrgServices}/api/v1/sf/employeeHeirarchyDetails?employeeId=${params?.userId}`,
          {
            headers: {
              Authorization: `Bearer ${userReducerState?.token}`,
            },
          }
        ).then((res) => res.json()),
        fetch(
          `${process.env.REACT_APP_WorkOrgServices}/api/v1/sf/employeeTeamDetails?employeeId=${params?.userId}`,
          {
            headers: {
              Authorization: `Bearer ${userReducerState?.token}`,
            },
          }
        ).then((res) => res.json()),
      ])
        .then(
          ([
            employeeDetailData,
            employeeHeirarchyDetailsData,
            employeeTeamDetailData,
          ]) => {
            var child = formateOrganizationView(
              employeeHeirarchyDetailsData,
              params?.userId
            );
            // console.log(child);
            setEmployeeDetail({
              ...employeeDetailData?.data,
              organization: employeeHeirarchyDetailsData?.data || {},
              teams: employeeTeamDetailData?.data || [],
            });
            setLoad(false);
          }
        )
        .catch((err) => {
          setLoad(false);
        });
    }
  }, [params?.userId, userReducerState?.token]);

  return (
    <>
      {load ? (
        <Loading load={load} />
      ) : (
        <Grid container>
          <Grid
            item
            xs={3}
            style={{
              padding: 10,
              height: "91vh",
              boxShadow: "0px 8px 24px rgba(192, 192, 192, 0.25)",
            }}
          >
            <Button
              variant="text"
              color="primary"
              startIcon={<IoMdArrowBack />}
              style={{ fontSize: 12 }}
              onClick={() => {
                window.location.href = `${location?.search.replace(
                  "?redirect=",
                  ""
                )}`;
              }}
            >
              Back to home
            </Button>

            <CustomAvatar
              name={`${employeeDetail?.firstName} ${employeeDetail?.lastName}`}
              src={
                employeeDetail?.imageType && employeeDetail?.profilePic
                  ? `data:${employeeDetail?.imageType};base64,${employeeDetail?.profilePic}`
                  : null
              }
              size="large"
              style={{}}
              className={classes.avatar}
            />

            <Typography
              style={{ fontSize: 18, fontWeight: 500, textAlign: "center" }}
            >
              {`${employeeDetail?.firstName} ${employeeDetail?.lastName}`}
            </Typography>

            <Typography style={{ fontSize: 14, textAlign: "center" }}>
              {employeeDetail?.designation}
            </Typography>

            <Divider style={{ margin: 8 }} />

            <Grid container style={{ padding: "2px 0px" }}>
              <Grid item xs={2} align="center">
                <BsPersonBadge />
              </Grid>

              <Grid item xs={10}>
                <Typography style={{ fontSize: 14 }}>
                  {employeeDetail?.employeeId}
                </Typography>
              </Grid>
            </Grid>

            <Grid container style={{ padding: "2px 0px" }}>
              <Grid item xs={2} align="center">
                <MdOutlineCall />
              </Grid>

              <Grid item xs={10}>
                <Typography style={{ fontSize: 14 }}>
                  {employeeDetail?.officePhone}
                </Typography>
              </Grid>
            </Grid>

            <Grid container style={{ padding: "2px 0px" }}>
              <Grid item xs={2} align="center">
                <AiOutlineMail />
              </Grid>

              <Grid item xs={10}>
                <Typography style={{ fontSize: 14 }}>
                  {employeeDetail?.officeEmail}
                </Typography>
              </Grid>
            </Grid>

            <Grid container style={{ padding: "2px 0px" }}>
              <Grid item xs={2} align="center">
                <IoLocationOutline />
              </Grid>

              <Grid item xs={10}>
                <Typography style={{ fontSize: 14 }}>
                  {employeeDetail?.countryCode}
                </Typography>
              </Grid>
            </Grid>

            <Grid container style={{ padding: "2px 0px" }}>
              <Grid item xs={2} align="center">
                <GoCalendar />
              </Grid>

              <Grid item xs={10}>
                <Typography style={{ fontSize: 14 }}>
                  {employeeDetail?.joiningDate}
                </Typography>
              </Grid>
            </Grid>

            <Divider style={{ margin: 8 }} />

            <div
              style={{ display: "flex", flexDirection: "column", padding: 8 }}
            >
              <Typography
                style={{ fontSize: 12, fontWeight: 300, color: "#666666" }}
              >
                Department
              </Typography>

              <Typography style={{ fontSize: 14 }}>
                {employeeDetail?.department}
              </Typography>
            </div>

            <div
              style={{ display: "flex", flexDirection: "column", padding: 8 }}
            >
              <Typography
                style={{ fontSize: 12, fontWeight: 300, color: "#666666" }}
              >
                Reports to
              </Typography>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (
                    employeeDetail?.projectManagerId &&
                    employeeDetail?.projectManagerId !== "null"
                  ) {
                    navigate(`/${employeeDetail?.projectManagerId}`);
                  }
                }}
              >
                <IoPersonCircleOutline
                  style={{ marginRight: 4, color: "#3f51b5" }}
                />

                <Typography color="primary" style={{ fontSize: 14 }}>
                  {employeeDetail?.projectManagerName}
                </Typography>
              </div>
            </div>
          </Grid>

          <Grid
            item
            xs={9}
            className="woScroll"
            style={{
              height: "91vh",
            }}
            ref={ref}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                position: "sticky",
                top: 0,
                zIndex: 99,
                left: 0,
                right: 0,
                borderBottom: "1px solid #E5E5E5",
                backgroundColor: "white",
              }}
            >
              <Typography
                onClick={() => {
                  document.getElementById("profile").scrollIntoView();
                  setSelectedTab("profile");
                }}
                style={{
                  padding: 8,
                  margin: "0px 24px",
                  fontSize: 16,
                  borderBottom:
                    selectedTab === "profile" ? "2px solid #0054a7" : "",
                  fontWeight: selectedTab === "profile" ? "bold" : "400",
                  cursor: "pointer",
                }}
              >
                Profile
              </Typography>

              <Typography
                onClick={() => {
                  document.getElementById("organization").scrollIntoView();
                  setSelectedTab("organization");
                }}
                style={{
                  padding: 8,
                  margin: "0px 24px",
                  fontSize: 16,
                  borderBottom:
                    selectedTab === "organization" ? "2px solid #0054a7" : "",
                  fontWeight: selectedTab === "organization" ? "bold" : "400",
                  cursor: "pointer",
                }}
              >
                Organization
              </Typography>

              <Typography
                onClick={() => {
                  document.getElementById("team").scrollIntoView();
                  setSelectedTab("team");
                }}
                style={{
                  padding: 8,
                  margin: "0px 24px",
                  fontSize: 16,
                  borderBottom:
                    selectedTab === "team" ? "2px solid #0054a7" : "",
                  fontWeight: selectedTab === "team" ? "bold" : "400",
                  cursor: "pointer",
                }}
              >
                Team
              </Typography>
            </div>

            <div id="profile" name="profile" className="element">
              <Paper
                elevation={0}
                style={{
                  boxShadow:
                    "0px 0px 16px rgba(207, 207, 207, 0.25), 0px 0px 8px rgba(255, 252, 252, 0.25), 0px 0px 4px rgba(249, 249, 249, 0.25), 0px 0px 2px #E0E0E0",
                  borderRadius: 4,
                  margin: 16,
                }}
              >
                <Typography
                  style={{
                    backgroundColor: "#f1f5fe",
                    fontSize: 14,
                    fontWeight: 700,
                    padding: 10,
                  }}
                >
                  Basic Info
                </Typography>

                <Grid container>
                  <Grid
                    item
                    xs={6}
                    style={{
                      borderRight: "1px solid #E5E5E5",
                    }}
                  >
                    <DetailEntry
                      label={"Employee ID"}
                      value={employeeDetail?.employeeId}
                      valueStyle={{}}
                    />

                    <DetailEntry
                      label={"First Name"}
                      value={employeeDetail?.firstName}
                      valueStyle={{}}
                    />

                    <DetailEntry
                      label={"Last Name"}
                      value={employeeDetail?.lastName}
                      valueStyle={{}}
                    />

                    <DetailEntry
                      label={"Official Email"}
                      value={employeeDetail?.officeEmail}
                      valueStyle={{
                        color: "#0054A7",
                      }}
                      onClick={(e) => {
                        // console.log(navigator);
                        window.open(`mailto:${employeeDetail?.officeEmail}`);
                      }}
                    />

                    <DetailEntry
                      label={"Designation-(Competancy)"}
                      value={employeeDetail?.designation}
                      valueStyle={{}}
                    />

                    <DetailEntry
                      last
                      label={"Employee Status"}
                      value={employeeDetail?.employeeStatus}
                      valueStyle={{ color: "green" }}
                      ValueIcon={
                        <div
                          style={{
                            width: 8,
                            height: 8,
                            marginRight: 8,
                            backgroundColor: "green",
                            borderRadius: "50%",
                          }}
                        />
                      }
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <DetailEntry
                      label={"Date of Joining"}
                      value={employeeDetail?.joiningDate}
                      valueStyle={{}}
                    />

                    <DetailEntry
                      label={"Total Work Experience"}
                      value={employeeDetail?.totalExperience}
                      valueStyle={{}}
                    />

                    <DetailEntry
                      label={"Previous Experience"}
                      value={"0 Years 11 Months"}
                      valueStyle={{}}
                    />

                    <DetailEntry
                      label={"Mobile Number"}
                      value={employeeDetail?.personalPhone}
                      valueStyle={{}}
                    />

                    <DetailEntry
                      label={"Work Phone"}
                      value={employeeDetail?.officePhone}
                      valueStyle={{}}
                    />
                  </Grid>
                </Grid>
              </Paper>

              <Paper
                elevation={0}
                style={{
                  boxShadow:
                    "0px 0px 16px rgba(207, 207, 207, 0.25), 0px 0px 8px rgba(255, 252, 252, 0.25), 0px 0px 4px rgba(249, 249, 249, 0.25), 0px 0px 2px #E0E0E0",
                  borderRadius: 4,
                  margin: 16,
                }}
              >
                <Typography
                  style={{
                    backgroundColor: "#f1f5fe",
                    fontSize: 14,
                    fontWeight: 700,
                    padding: 10,
                  }}
                >
                  Work Details
                </Typography>

                <Grid container>
                  <Grid
                    item
                    xs={6}
                    style={{
                      borderRight: "1px solid #E5E5E5",
                    }}
                  >
                    <DetailEntry
                      label={"Project Manager"}
                      value={employeeDetail?.projectManagerName}
                      valueStyle={{ color: "#0054A7" }}
                      ValueIcon={
                        <IoPersonCircleOutline
                          style={{ marginRight: 4, color: "#3f51b5" }}
                        />
                      }
                      onClick={() => {
                        if (
                          employeeDetail?.projectManagerId &&
                          employeeDetail?.projectManagerId !== "null"
                        ) {
                          navigate(`/${employeeDetail?.projectManagerId}`);
                        }
                      }}
                    />

                    <DetailEntry
                      label={"People Manager"}
                      value={employeeDetail?.peopleManagerName}
                      valueStyle={{ color: "#0054A7" }}
                      ValueIcon={
                        <IoPersonCircleOutline
                          style={{ marginRight: 4, color: "#3f51b5" }}
                        />
                      }
                      onClick={() => {
                        if (
                          employeeDetail?.peopleManagerId &&
                          employeeDetail?.peopleManagerId !== "null"
                        ) {
                          navigate(`/${employeeDetail?.peopleManagerId}`);
                        }
                      }}
                    />

                    <DetailEntry
                      label={"Primary Skill"}
                      value={"SAP UI5"}
                      valueStyle={{}}
                    />

                    <DetailEntry
                      last
                      label={"Secondary Skill"}
                      value={"HTML"}
                      valueStyle={{}}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <DetailEntry
                      label={"Role"}
                      value={employeeDetail?.role}
                      valueStyle={{}}
                    />

                    <DetailEntry
                      label={"Work Location"}
                      value={employeeDetail?.countryCode}
                      valueStyle={{}}
                    />

                    <DetailEntry
                      label={"Region"}
                      value={employeeDetail?.countryCode}
                      valueStyle={{}}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </div>

            <div id="organization" name="organization" className="element">
              <Paper
                elevation={0}
                style={{
                  boxShadow:
                    "0px 0px 16px rgba(207, 207, 207, 0.25), 0px 0px 8px rgba(255, 252, 252, 0.25), 0px 0px 4px rgba(249, 249, 249, 0.25), 0px 0px 2px #E0E0E0",
                  borderRadius: 4,
                  margin: 16,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    backgroundColor: "#f1f5fe",
                    padding: 10,
                  }}
                >
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Organization
                  </Typography>

                  <div
                    style={{ display: "flex", alignItems: "center" }}
                    onClick={() => {
                      setFullScreenOrganizationView(
                        !fullScreenOrganizationView
                      );
                    }}
                  >
                    {fullScreenOrganizationView ? (
                      <AiOutlineFullscreenExit style={{ cursor: "pointer" }} />
                    ) : (
                      <AiOutlineFullscreen style={{ cursor: "pointer" }} />
                    )}
                  </div>
                </div>

                <div
                  style={{ padding: 16, width: "70vw" }}
                  className="woScroll"
                >
                  <Organization org={employeeDetail?.organization} />
                </div>
              </Paper>

              <FullScreenOrganization
                open={fullScreenOrganizationView}
                onClose={() => setFullScreenOrganizationView(false)}
                employeeDetail={employeeDetail}
              />
            </div>

            <div id="team" name="team" className="element">
              <Paper
                elevation={0}
                style={{
                  boxShadow:
                    "0px 0px 16px rgba(207, 207, 207, 0.25),0px 0px 8px rgba(255, 252, 252, 0.25), 0px 0px 4px rgba(249, 249, 249, 0.25),0px 0px 2px #e0e0e0",
                  borderSadius: "4px",
                  margin: "16px",
                }}
              >
                <Typography
                  style={{
                    backgroundColor: "#f1f5fe",
                    fontSize: 14,
                    fontWeight: 700,
                    padding: 10,
                  }}
                >
                  Team
                </Typography>

                {employeeDetail?.teams?.map((team, index) => (
                  <Team key={index} team={team} />
                ))}
              </Paper>

              {/* <Paper
            elevation={0}
            style={{
              boxShadow:
                "0px 0px 16px rgba(207, 207, 207, 0.25), 0px 0px 8px rgba(255, 252, 252, 0.25), 0px 0px 4px rgba(249, 249, 249, 0.25), 0px 0px 2px #E0E0E0",
              borderRadius: 4,
              margin: 16,
            }}
          >
            <Typography
              style={{
                backgroundColor: "#f1f5fe",
                fontSize: 14,
                fontWeight: 700,
                padding: 10,
              }}
            >
              Personal Details
            </Typography>

            <Grid container>
              <Grid
                item
                xs={6}
                style={{
                  borderRight: "1px solid #E5E5E5",
                }}
              >
                <DetailEntry
                  label={"DOB"}
                  value={"24-09-1973"}
                  valueStyle={{}}
                />

                <DetailEntry label={"Age"} value={"47"} valueStyle={{}} />

                <DetailEntry
                  label={"Personal Email"}
                  value={"anja.klein@bestrunsap.com"}
                  valueStyle={{ color: "#0054A7" }}
                />

                <DetailEntry
                  label={"Father's Name"}
                  value={"Nathan Klein"}
                  valueStyle={{}}
                />

                <DetailEntry
                  label={"Personal Contact Number"}
                  value={"+49- 9456789012"}
                  valueStyle={{}}
                />

                <DetailEntry
                  label={"Emergency Contact Number"}
                  value={"+49- 9456789012"}
                  valueStyle={{}}
                />

                <DetailEntry
                  last
                  label={"Region"}
                  value={"Germany"}
                  valueStyle={{}}
                />
              </Grid>

              <Grid item xs={6}>
                <DetailEntry
                  label={"Nationality"}
                  value={"Germany"}
                  valueStyle={{}}
                />

                <DetailEntry
                  label={"Gender"}
                  value={"Female"}
                  valueStyle={{}}
                />

                <DetailEntry
                  label={"Marital Status"}
                  value={"Married"}
                  valueStyle={{}}
                />

                <DetailEntry
                  label={"Blood Group"}
                  value={"O+"}
                  valueStyle={{}}
                />

                <DetailEntry
                  label={"Passport Number"}
                  value={"-"}
                  valueStyle={{}}
                />

                <DetailEntry
                  label={"Nationality ID Number"}
                  value={"-"}
                  valueStyle={{}}
                />
              </Grid>
            </Grid>
          </Paper>

          <Paper
            elevation={0}
            style={{
              boxShadow:
                "0px 0px 16px rgba(207, 207, 207, 0.25), 0px 0px 8px rgba(255, 252, 252, 0.25), 0px 0px 4px rgba(249, 249, 249, 0.25), 0px 0px 2px #E0E0E0",
              borderRadius: 4,
              margin: 16,
            }}
          >
            <Typography
              style={{
                backgroundColor: "#f1f5fe",
                fontSize: 14,
                fontWeight: 700,
                padding: 10,
              }}
            >
              Bank Details
            </Typography>

            <Grid container>
              <Grid
                item
                xs={6}
                style={{
                  borderRight: "1px solid #E5E5E5",
                }}
              >
                <DetailEntry
                  label={"Bank Name"}
                  value={"German bank"}
                  valueStyle={{}}
                />

                <DetailEntry
                  last
                  label={"IFSC Number"}
                  value={"ICICI000234"}
                  valueStyle={{}}
                />
              </Grid>

              <Grid item xs={6}>
                <DetailEntry
                  label={"Account Holder Number"}
                  value={"23456789"}
                  valueStyle={{}}
                />

                <DetailEntry
                  last
                  label={"Account Holder Name"}
                  value={"Anja Klein"}
                  valueStyle={{}}
                />
              </Grid>
            </Grid>
          </Paper> */}
            </div>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default EmpDetail;
