import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Keycloak from "keycloak-js";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./Redux/store";

const keycloak = Keycloak(
  window.location?.hostname === "localhost"
    ? "/localhostKeycloak.json"
    : "/azuredevKeycloak.json"
);

const root = ReactDOM.createRoot(document.getElementById('root'));

keycloak.init({ onLoad: "login-required" }).then((authenticated) => {
  return root.render(
    authenticated ? (
      <Provider store={store}>
        <App keycloak={keycloak} />
      </Provider>
    ) : null,
    document.getElementById("root")
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

