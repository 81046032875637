import React, { useState, useEffect } from "react";
import {
  IconButton,
  Typography,
  Grid,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
// import {
//   UserModule,
//   RoleModule,
//   SystemModule,
//   GroupModule,
// } from "@cw/cherrywork-iwa";
import Users from "@cw/cherrywork-iwa/Users";
import BasicUserInfo from "@cw/cherrywork-iwa/BasicUserInfo";
import UserAssociatedGroups from "@cw/cherrywork-iwa/UserAssociatedGroups";
import UserAssignedRoles from "@cw/cherrywork-iwa/UserAssignedRoles";
import {
  UserDefaultFeatures,
  UserAdditionalFeatures,
} from "@cw/cherrywork-iwa/UserExceptionalFeatures";
import UserActivityLog from "@cw/cherrywork-iwa/UserActivityLog";
import UserAssignedTemplateRole from "@cw/cherrywork-iwa/UserAssignedTemplateRole";
import Roles from "@cw/cherrywork-iwa/Roles";
import IWADashboard from "@cw/cherrywork-iwa/IWADashboard";
import BasicRoleInfo from "@cw/cherrywork-iwa/BasicRoleInfo";
import RoleAssignedApis from "@cw/cherrywork-iwa/RoleAssignedApis";
import RoleAssignedFeatures from "@cw/cherrywork-iwa/RoleAssignedFeatures";
import RoleAssignedUsers from "@cw/cherrywork-iwa/RoleAssignedUsers";
import RoleAssociateRoles from "@cw/cherrywork-iwa/RoleAssociateRoles";
import RoleAssignedTemplateUser from "@cw/cherrywork-iwa/RoleAssignedTemplateUser";
import RoleAssociatedScopes from "@cw/cherrywork-iwa/RoleAssociatedScopes";
import Groups from "@cw/cherrywork-iwa/Groups";
import BasicGroupInfo from "@cw/cherrywork-iwa/BasicGroupInfo";
import GroupAssociatedUsers from "@cw/cherrywork-iwa/GroupAssociatedUsers";
import GroupAssignedRoles from "@cw/cherrywork-iwa/GroupAssignedRoles";
import Systems from "@cw/cherrywork-iwa/Systems";
import BasicApplicationInfo from "@cw/cherrywork-iwa/BasicApplicationInfo";
import Modules from "@cw/cherrywork-iwa/Modules";
import Features from "@cw/cherrywork-iwa/Features";
import Apis from "@cw/cherrywork-iwa/Apis";
import Payloads from "@cw/cherrywork-iwa/Payloads";
import ActivityAssociatedApis from "@cw/cherrywork-iwa/ActivityAssociatedApis";
import UserApplicationAccess from "@cw/cherrywork-iwa/UsersApplicationAccess";
import { useSelector } from "react-redux";
import {
  backArrowHeight,
  tabHeight,
} from "../../Data/cssConstant";
import { BiArrowBack } from "react-icons/bi";

const formateApiUrl = (url, urls) => {
  const [extra, baseUrl, ...rest] = url?.split("/");
  const dest = urls?.find((u) => u?.name === baseUrl);
  if (dest) {
    return [dest?.url, ...rest]?.join("/");
  }
  return url;
};

const useStyle = makeStyles((theme) => ({
  headerContainer: {
    display: "flex",
    alignItems: "center",
    padding: 10,
    gap: 40,
    height: tabHeight,
  },
  headerItem: {
    color: "rgba(0, 0, 0, 0.6)",
    fontWeight: "normal",
    cursor: "pointer",
    fontSize: 14,
    marginLeft: 8,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  headerItemSelected: {
    color: "#1976d2",
    fontWeight: "bold",
  },
}));

function Index({ selectedSubItem }) {
  const userReducerState = useSelector(
    (state) => state.userReducer
  );
  // 
  //
  const token = userReducerState?.token
  // `eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJJNExzTXlreEQ4czlXdFhOLUxaTXoxbDd3SmxfTmZmNGN5WkM5MEpZbklVIn0.eyJleHAiOjE2OTI2ODY4MTgsImlhdCI6MTY5MjY3OTY4OSwiYXV0aF90aW1lIjoxNjkyNjc5NjE4LCJqdGkiOiJhMTUyOGQyYi0yZmI0LTQxZWQtOWY4Ny00ZmFlNGM1N2FkNDMiLCJpc3MiOiJodHRwczovL2tleWNsb2FrLmNoZXJyeXdvcmtwcm9kdWN0cy5jb20vYXV0aC9yZWFsbXMvRGlnaXRhbEFwcGxpY2F0aW9uU3VpdGUiLCJhdWQiOlsiYnJva2VyIiwiYWNjb3VudCJdLCJzdWIiOiJjODMxZTg2Ny01MDJhLTQ5MjMtYmNkNi0wY2VjNjE1YmFmZGIiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJsb2NhbGhvc3QiLCJub25jZSI6Ijg3ODk0NDAyLWJmZGEtNDg2YS04YWI4LTRhMDdiZmEzNGMxZiIsInNlc3Npb25fc3RhdGUiOiIxZmFjNmIxNC1iNDdiLTQyY2ItYjM0Yy1lZmE1NWM1OWJkNzciLCJhY3IiOiIwIiwiYWxsb3dlZC1vcmlnaW5zIjpbImh0dHA6Ly9sb2NhbGhvc3Q6MzAwMSIsImh0dHA6Ly9sb2NhbGhvc3Q6MzAwMCJdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsiZGVmYXVsdC1yb2xlcy1pbnRlbGxpZ2VudCB0YXNrIG1hbmFnZW1lbnQiLCJhY2Nlc3MtaWRtIiwib2ZmbGluZV9hY2Nlc3MiLCJhY2Nlc3MtdGFzayIsImFjY2Vzcy1tb2RlbGVyIiwidW1hX2F1dGhvcml6YXRpb24iLCJhY2Nlc3MtYWRtaW4iXX0sInJlc291cmNlX2FjY2VzcyI6eyJicm9rZXIiOnsicm9sZXMiOlsicmVhZC10b2tlbiJdfSwiYWNjb3VudCI6eyJyb2xlcyI6WyJtYW5hZ2UtYWNjb3VudCIsIm1hbmFnZS1hY2NvdW50LWxpbmtzIiwidmlldy1wcm9maWxlIl19fSwic2NvcGUiOiJvcGVuaWQgdWFhLnVzZXIgcHJvZmlsZSBlbWFpbCIsInNpZCI6IjFmYWM2YjE0LWI0N2ItNDJjYi1iMzRjLWVmYTU1YzU5YmQ3NyIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJuYW1lIjoiQWFzdGlrIE1hbmRpbCIsInBpZCI6IlAwMDA0MjkiLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJhYXN0aWsubWFuZGlsQGluY3R1cmUuY29tIiwiZ2l2ZW5fbmFtZSI6IkFhc3RpayIsImZhbWlseV9uYW1lIjoiTWFuZGlsIiwiZW1haWwiOiJhYXN0aWsubWFuZGlsQGluY3R1cmUuY29tIn0.Fo92eBWky1zd4-Wm0bFYwUw1dR6eHldy-Dn3bPfqUkgJigDO-VXFwNIZ3hnfOydmNQ6WLdXfINq9nXZd5ZY7Qgtf0r7vV69MXzdNnMQvR6RAL6P94pXiEhRdUJZZzFckjNQKDh-Rctpev57oOKMm-n9jI6W_Et93szJhv2jP0dLM7RRVUf-tuvhouwgS5ygUifGOQv6qMTZ8EQqNZJZkuNNKTgXp0k7uk6rof2eSWXIkrw0nFQh5Egk2IWHUz3vz0W3ZnU9C-l3gxAL6hJq17mbiqjhEYM8o2zLBWAwDAsls3i5rS9pEF_FDrPWgoomPyT3n3j8Wc0mvIqTOiyVxpg`;
  const [configResult, setConfigResult] = useState({
    consumerAppId: {
      SAP_BTP: 67,
    },
    allowedFeatures: [],
    destinations: [
      {
        name: "IWAServices",
        // url: process.env.REACT_APP_WorkOrgServices,
        // url: "http://localhost:8080",
        // url: "https://iwaservicesdev.cherryworkproducts.com",
        // url: "http://20.204.209.99:8080",
        // url: "https://ipmservicesqa.cherryworkproducts.com/rest"
        url: "https://wgservicesdev.cherryworkproducts.com",
      },
      {
        name: "CONFIG_SERVER_DESTINATION",
        url: "https://configservice.cherryworkproducts.com",
      },
      {
        name: "IPMServices",
        url: "https://ipmservicesqa.cherryworkproducts.com",
      },
      {
        name: "ITMGraphServices",
        url: "https://devboard.cherryworkproducts.com",
      },
      {
        Description: "",
        Name: "ConfigServer",
        URL: "https://configservice.cherryworkproducts.com",
      },
      {
        Description: "",
        Name: "CrudApiServices",
        URL: "https://crudservicesdev.cherryworkproducts.com",
      },
    ],
    application: "IWA",
    env: "iwa_dev",
    useApplication: 67,
  });

  const [users, setusers] = useState([]);
  const [roles, setroles] = useState([]);
  const [roleTemplates, setRoleTemplates] = useState([]);
  const [groups, setgroups] = useState([]);
  const [applications, setapplications] = useState([]);
  const [modules, setmodules] = useState([]);
  const [features, setfeatures] = useState([]);
  const [apis, setapis] = useState([]);
  const [payloads, setpayloads] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [appId, setAppId] = useState(
    "cw-apps-xsuaa!t197806"
  );

  const getUsers = () => {
    const getAllUsersUrl = formateApiUrl(
      `/IWAServices/api/v1/users`,
      configResult?.destinations
    );
    const getAllUsersRequestParam = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    fetch(getAllUsersUrl, getAllUsersRequestParam)
      .then((res) => res.json())
      .then((data) => {
        setusers(data?.data || []);
      })
      .catch((err) => {
        setusers([]);
      });
  };
  const getRoles = () => {
    const getAllRolesUrl = formateApiUrl(
      `/IWAServices/api/v1/roles`,
      configResult?.destinations
    );
    const getAllRolesRequestParams = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    fetch(getAllRolesUrl, getAllRolesRequestParams)
      .then((res) => res.json())
      .then((data) => {
        setroles(data?.data || []);
      })
      .catch((err) => {
        setroles([]);
      });
  };
  const getRoleTemplates = () => {
    const getGroupsUrl = formateApiUrl(
      `/IWAServices/api/v1/roles/roleTemplates`,
      configResult?.destinations
    );
    const getGroupsRequestParam = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    fetch(getGroupsUrl, getGroupsRequestParam)
      .then((res) => res.json())
      .then((data) => {
        setRoleTemplates(data?.data || []);
      })
      .catch((err) => {
        setRoleTemplates([]);
      });
  };
  const getGroups = () => {
    const getGroupsUrl = formateApiUrl(
      `/IWAServices/api/v1/groups`,
      configResult?.destinations
    );
    const getGroupsRequestParam = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    fetch(getGroupsUrl, getGroupsRequestParam)
      .then((res) => res.json())
      .then((data) => {
        setgroups(data?.data || []);
      })
      .catch((err) => {
        setgroups([]);
      });
  };
  const getApplications = () => {
    const getAllApplicationsUrl = formateApiUrl(
      `/IWAServices/api/v1/applications`,
      configResult?.destinations
    );
    const getAllApplicationsRequestParams = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    fetch(
      getAllApplicationsUrl,
      getAllApplicationsRequestParams
    )
      .then((res) => res.json())
      .then((data) => {
        setapplications(data?.data || []);
      })
      .catch((err) => {
        setapplications([]);
      });
  };
  const getModules = () => {
    const getAllEntitiesUrl = formateApiUrl(
      `/IWAServices/api/v1/entities`,
      configResult?.destinations
    );
    const getAllEntitiesRequestParams = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    fetch(getAllEntitiesUrl, getAllEntitiesRequestParams)
      .then((res) => res.json())
      .then((data) => {
        setmodules(data?.data || []);
      })
      .catch((err) => {
        setmodules([]);
      });
  };
  const getFeatures = () => {
    const getAllActivitiesUrl = formateApiUrl(
      `/IWAServices/api/v1/activities`,
      configResult?.destinations
    );
    const getAllActivitiesRequestParams = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    fetch(
      getAllActivitiesUrl,
      getAllActivitiesRequestParams
    )
      .then((res) => res.json())
      .then((data) => {
        setfeatures(data?.data || []);
      })
      .catch((err) => {
        setfeatures([]);
      });
  };
  const getApis = () => {
    const getAllApisUrl = formateApiUrl(
      `/IWAServices/api/v1/apiPayload`,
      configResult?.destinations
    );
    const getAllApisRequestParams = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    fetch(getAllApisUrl, getAllApisRequestParams)
      .then((res) => res.json())
      .then((data) => {
        setapis(data?.data || []);
      })
      .catch((err) => {
        setapis([]);
      });
  };
  const getPayloads = () => {
    const getAllPayloadsUrl = formateApiUrl(
      `/IWAServices/api/v1/apiPayload/payload`,
      configResult?.destinations
    );
    const getAllPayloadsRequestParams = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    fetch(getAllPayloadsUrl, getAllPayloadsRequestParams)
      .then((res) => res.json())
      .then((data) => {
        setpayloads(data?.data || []);
      })
      .catch((err) => {
        setpayloads([]);
      });
  };
  const getTemplates = () => {
    const getTemplatesUrl = formateApiUrl(
      `/IPMServices/rest/v1/pcr-template?isActive=true`,
      configResult?.destinations
    );
    const getTemplatesRequestParam = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    fetch(getTemplatesUrl, getTemplatesRequestParam)
      .then((res) => res.json())
      .then((data) => {
        setTemplates(data?.data || []);
      })
      .catch((err) => {
        setTemplates([]);
      });
  };

  useEffect(() => {
    getUsers();
    getRoles();
    getRoleTemplates();
    getGroups();
    getApplications();
    getModules();
    getFeatures();
    getApis();
    getPayloads();
    getTemplates();
  }, []);

  return (
    <div
      style={{
        width: "92vw",
        height: "90vh",
      }}
    >
      {selectedSubItem === "Systems" && (
        <div style={{ height: "100%", width: "90vw" }}>
          <SystemPage
            token={token}
            configResult={configResult}
            title="Systems"
            activityTabDetails={[
              {
                id: "Associated_Api",
                title: "Associated Api",
              },
            ]}
            applications={applications}
            setapplications={setapplications}
            users={users}
            modules={modules}
            setmodules={setmodules}
            features={features}
            setfeatures={setfeatures}
            apis={apis}
            payloads={payloads}
            getApplications={getApplications}
            getModules={getModules}
            getFeatures={getFeatures}
            getApis={getApis}
            getPayloads={getPayloads}
            applicationTabDetails={[
              { id: "Basic_Info", title: "Basic Info" },
              { id: "Modules", title: "Modules" },
              { id: "Apis", title: "Apis" },
              { id: "Users", title: "Users" },
            ]}
            enabledViewSystem={true}
            enabledAddSystem={true}
            enabledUpdateSystem={true}
            enabledDeleteSystem={true}
            enabledViewModule={true}
            enabledAddModule={true}
            enabledUpdateModule={true}
            enabledDeleteModule={true}
            enabledViewFeature={true}
            enabledAddFeature={true}
            enabledUpdateFeature={true}
            enabledDeleteFeature={true}
            enabledViewApi={true}
            enabledAddApi={true}
            enabledUpdateApi={true}
            enabledDeleteApi={true}
            enabledViewPayload={true}
            enabledAddPayload={false}
            enabledUpdatePayload={false}
            enabledDeletePayload={false}
            enabledActivityLog={true}
            enableSyncFeature={true}
            enabledViewActivityAssociatedApis={true}
            enabledAddActivityAssociatedApis={true}
            enabledDeleteActivityAssociatedApis={true}
            enabledActivateActivityAssociatedApis={true}
            enabledAuditLog={true}
          />
        </div>
      )}

      {selectedSubItem === "Groups" && (
        <div style={{ height: "100%", width: "90vw" }}>
          <GroupPage
            token={token}
            configResult={configResult}
            title={"Groups"}
            groups={groups}
            setGroups={setgroups}
            applications={applications}
            users={users}
            roles={roles}
            getGroups={getGroups}
            groupTabDetails={[
              { id: "Basic_Info", title: "Basic Info" },
              {
                id: "Associated_User",
                title: "Associated User",
              },
              {
                id: "Assigned_Role",
                title: "Assigned Role",
              },
            ]}
            visibleCol={{
              status: true, // if you wanted to view status column
              createdBy: true, // if you wanted to view createdBy column
              createdOn: true, // if you wanted to view createdOn column
              updatedBy: true, // if you wanted to view updatedBy column
              updatedOn: true, // if you wanted to view updatedOn column
            }}
            dateFormat={"DD-MM-yyyy hh:mm:ss"}
            enabledAuditLog={true} // if you want to enable audit-log feature
            enabledActivityLog={true} // if you want to enable activity-log feature
            enabledViewGroup={true}
            enabledAddGroup={true}
            enabledUpdateGroup={true}
            enabledDeleteGroup={true}
            // enabledActivateGroup={true}
            enabledViewGroupAssociatedUser={true}
            enabledAddGroupAssociatedUser={true}
            enabledDeleteGroupAssociatedUser={true}
            // enabledActivateGroupAssociatedUser={true}
            enabledViewGroupAssignedRole={true}
            enabledAddGroupAssignedRole={true}
            enabledDeleteGroupAssignedRole={true}
            // enabledActivateGroupAssignedRole={true}
          />
        </div>
      )}

      {selectedSubItem === "Roles" && (
        <div style={{ height: "100%", width: "90vw" }}>
          <RolePage
            token={token}
            configResult={configResult}
            appId={appId}
            title={"Roles"}
            applications={applications}
            roles={roles}
            setroles={setroles}
            roleTemplates={roleTemplates}
            setRoleTemplates={setRoleTemplates}
            users={users}
            modules={modules}
            features={features}
            apis={apis}
            templates={templates}
            getRoles={getRoles}
            getRoleTemplates={getRoleTemplates}
            roleTabDetails={[
              { id: "Basic_Info", title: "Basic Info" },
              {
                id: "Associated_Role",
                title: "Associated Role",
              },
              // {
              //   id: "Assigned_Users",
              //   title: "Assigned Users",
              // },
              {
                id: "Assigned_Features",
                title: "Assigned Features",
              },
              {
                id: "Assigned_Apis",
                title: "Assigned Apis",
              },
              {
                id: "Assigned_Template_User",
                title: "Assigned PCR-Templates",
              },
              {
                id: "Associated_Scopes",
                title: "Associated Scopes",
              },
            ]}
            visibleCol={{
              status: true, // if you wanted to view status column
              createdBy: true, // if you wanted to view createdBy column
              createdOn: true, // if you wanted to view createdOn column
              updatedBy: true, // if you wanted to view updatedBy column
              updatedOn: true, // if you wanted to view updatedOn column
            }}
            dateFormat={"DD-MM-yyyy hh:mm:ss"}
            enabledExport={true} // if you want to enable export feature
            enabledImportRole={true}
            enabledAuditLog={true} // if you want to enable audit-log feature
            enabledActivityLog={true} // if you want to enable activity-log feature
            enabledViewRole={true}
            enabledAddRole={true}
            enabledUpdateRole={true}
            enabledDeleteRole={true}
            // enabledActivateRole={true}
            enabledViewAssociatedRole={true}
            enabledAddAssociatedRole={true}
            enabledDeleteAssociatedRole={true}
            // enabledActivateAssociatedRole={true}
            enabledViewRoleAssignedUser={true}
            enabledAddRoleAssignedUser={true}
            enabledDeleteRoleAssignedUser={true}
            // enabledActivateRoleAssignedUser={true}
            enabledViewRoleAssignedFeatures={true}
            enabledAddRoleAssignedFeatures={true}
            enabledDeleteRoleAssignedFeatures={true}
            // enabledActivateRoleAssignedFeatures={true}
            enabledViewRoleAssignedApis={true}
            enabledAddRoleAssignedApis={false}
            enabledDeleteRoleAssignedApis={false}
            // enabledActivateRoleAssignedApis={true}
            enabledViewRoleAssignedTemplateUser={true}
            enabledAddRoleAssignedTemplateUser={true}
            enabledUpdateRoleAssignedTemplateUser={true}
            enabledDeleteRoleAssignedTemplateUser={true}
            // enabledActivateRoleAssignedTemplateUser={true}
            enabledViewRoleAssociatedScopes={true}
          />
        </div>
      )}

      {selectedSubItem === "Users" && (
        <div style={{ height: "100%", width: "90vw" }}>
          <UserPage
            token={token}
            configResult={configResult}
            title={"Users"}
            systems={[
              {
                name: "SapIas",
                label: "SAP-IAS Users",
                activityLog: {
                  name: "ADD_SAP_IAS_USER",
                  entityType: "Inserting User",
                  description: "Adding SAP IAS User",
                  entityId: "cw_users",
                  type: "Insert",
                  longDescription: "Adding SAP IAS User",
                },
              },
              {
                name: "Azure",
                label: "Azure AD Users",
                activityLog: {
                  name: "ADD_AZURE_USER",
                  entityType: "Inserting User",
                  description: "Adding Azure User",
                  entityId: "cw_users",
                  type: "Insert",
                  longDescription: "Adding Azure User",
                },
              },
              {
                name: "SapBtp",
                label: "SAP-BTP Users",
                activityLog: {
                  name: "ADD_SAP_BTP_USER",
                  entityType: "Inserting User",
                  description: "Adding SAP BTP User",
                  entityId: "cw_users",
                  type: "Insert",
                  longDescription: "Adding SAP BTP User",
                },
              },
            ]}
            users={users}
            setusers={setusers}
            setFilteredData={(data) => {
              // console.log("filtered data", data);
            }}
            groups={groups}
            applications={applications}
            roles={roles}
            templates={templates}
            visibleCol={{
              status: true, // if you wanted to view status column
              createdBy: true, // if you wanted to view createdBy column
              createdOn: true, // if you wanted to view createdOn column
              updatedBy: true, // if you wanted to view updatedBy column
              updatedOn: true, // if you wanted to view updatedOn column
            }}
            dateFormat={"DD-MM-yyyy hh:mm:ss"}
            getUsers={getUsers}
            userTabDetails={[
              { id: "Basic_Info", title: "Basic Info" },
              {
                id: "Associated_Groups",
                title: "Associated Groups",
              },
              {
                id: "Assigned_Roles",
                title: "Assigned Roles",
              },
              // { id: "Features", title: "Features" },
              {
                id: "Assigned_Template_Roles",
                title: "Assigned PCR-Templates",
              },
              {
                id: "Activity_Log",
                title: "Activity Log",
              },
            ]}
            userFeatureTabDetails={[
              {
                id: "Default_Features",
                title: "Default Features",
              },
              {
                id: "Exceptional_Features",
                title: "Exceptional Features",
              },
            ]}
            enabledWorkorg={false}
            enabledExport={true} // if you want to enable export feature
            enabledAuditLog={true} // if you want to enable audit-log feature
            enabledActivityLog={true} // if you want to enable activity-log feature
            enabledViewUser={true}
            enabledAddNewUser={false}
            enabledImportSapUser={false}
            enabledImportAzureUser={false}
            enabledUpdateUser={true}
            enabledDeleteUser={true}
            // enabledActivateUser={true}
            enabledViewUserCommunication={true}
            enabledAddUserCommunication={true}
            enabledDeleteUserCommunication={true}
            enabledViewUserGroup={true}
            enabledAddUserGroup={true}
            enabledDeleteUserGroup={true}
            // enabledActivateUserGroup={true}
            enabledViewUserRole={true}
            enabledAddUserRole={true}
            enabledDeleteUserRole={true}
            // enabledActivateUserRole={true}
            enabledViewUserDefaultFeature={true}
            enabledDeleteUserDefaultFeature={true}
            enabledViewUserAdditionalFeature={true}
            enabledAddUserAdditionalFeature={true}
            enabledDeleteUserAdditionalFeature={true}
            enabledViewUserAssignedTemplateRole={true}
            enabledAddUserAssignedTemplateRole={true}
            enabledUpdateUserAssignedTemplateRole={true}
            enabledDeleteUserAssignedTemplateRole={true}
            // enabledActivateUserAssignedTemplateRole={true}
            loggedInUser={{
              userId: "aastik.mandil@incture.com",
              emailId: "aastik.mandil@incture.com",
              pid: "aastik.mandil@incture.com",
            }}
            createUserFn={(data) => {
              console.log("user created successfully");
            }}
            deleteUserFn={(data) => {
              console.log("user deleted successfully");
            }}
            deleteMultipleUserFn={(data) => {
              console.log("user data successfully");
            }}
          />
        </div>
      )}

      {selectedSubItem === "Dashboard" && (
        <div style={{ height: "100%" }}>
          <IWADashboard
            token={token}
            configResult={configResult}
            env={"iwa_ipm"}
          />
        </div>
      )}
    </div>
  );
}

const UserPage = ({
  token,
  configResult,
  title,
  systems,
  users,
  setusers,
  setFilteredData,
  groups,
  applications,
  roles,
  templates,
  visibleCol,
  dateFormat,
  getUsers,
  userTabDetails,
  userFeatureTabDetails,
  enabledWorkorg,
  enabledExport,
  enabledAuditLog,
  enabledActivityLog,
  enabledViewUser,
  enabledAddNewUser,
  enabledImportSapUser,
  enabledImportAzureUser,
  enabledUpdateUser,
  enabledDeleteUser,
  enabledActivateUser,
  enabledViewUserCommunication,
  enabledAddUserCommunication,
  enabledDeleteUserCommunication,
  enabledViewUserGroup,
  enabledAddUserGroup,
  enabledDeleteUserGroup,
  enabledActivateUserGroup,
  enabledViewUserRole,
  enabledAddUserRole,
  enabledDeleteUserRole,
  enabledActivateUserRole,
  enabledViewUserDefaultFeature,
  enabledDeleteUserDefaultFeature,
  enabledViewUserAdditionalFeature,
  enabledAddUserAdditionalFeature,
  enabledDeleteUserAdditionalFeature,
  enabledViewUserAssignedTemplateRole,
  enabledAddUserAssignedTemplateRole,
  enabledUpdateUserAssignedTemplateRole,
  enabledDeleteUserAssignedTemplateRole,
  enabledActivateUserAssignedTemplateRole,
  loggedInUser,
  createUserFn,
  deleteUserFn,
  deleteMultipleUserFn,
}) => {
  const classes = useStyle();
  const [selectedUser, setSelectedUser] = useState(null);
  const [userTabName, setUserTabName] = useState(
    userTabDetails?.[0]?.id
  );
  const [userFeatureTabName, setUserFeatureTabName] =
    useState(userFeatureTabDetails?.[0]?.id);

  useEffect(() => {
    setUserTabName(userTabDetails?.[0]?.id);
    setUserFeatureTabName(userFeatureTabDetails?.[0]?.id);
  }, [selectedUser]);

  return (
    <>
      {selectedUser ? (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              height: backArrowHeight,
            }}
          >
            <IconButton
              size="small"
              onClick={() => {
                setSelectedUser(null);
                getUsers();
              }}
            >
              <BiArrowBack />
            </IconButton>
          </div>

          <div
            className={`${classes.headerContainer} iagScroll`}
          >
            {userTabDetails?.map((userTabDetail, index) => (
              <Typography
                key={index}
                className={`${classes.headerItem} ${
                  userTabName === userTabDetail?.id &&
                  classes.headerItemSelected
                }`}
                onClick={() =>
                  setUserTabName(userTabDetail?.id)
                }
              >
                {userTabDetail?.title}
              </Typography>
            ))}
          </div>

          {userTabName === "Basic_Info" && (
            <div
              style={{
                height: `calc(100% - ${backArrowHeight} - ${tabHeight})`,
                overflow: "overlay",
              }}
            >
              <div
                style={{
                  position: "relative",
                  height: "100%",
                }}
              >
                <BasicUserInfo
                  token={token}
                  configResult={configResult}
                  users={users}
                  selectedUser={selectedUser}
                  visibleCol={{
                    ...visibleCol,
                    status: false,
                    createdBy: false,
                    updatedOn: false,
                    updatedBy: false,
                    createdOn: false,
                  }}
                  dateFormat={dateFormat}
                  enabledUpdateUser={enabledUpdateUser}
                  enabledViewUserCommunication={enabledViewUserCommunication}
                  enabledAddUserCommunication={enabledAddUserCommunication}
                  enabledDeleteUserCommunication={enabledDeleteUserCommunication}
                  enabledActivityLog={enabledActivityLog}
                />
              </div>
            </div>
          )}

          {userTabName === "Associated_Groups" &&
            enabledViewUserGroup && (
              <div
                style={{
                  height: `calc(100% - ${backArrowHeight} - ${tabHeight})`,
                }}
              >
                <UserAssociatedGroups
                  token={token}
                  configResult={configResult}
                  groups={groups}
                  selectedUser={selectedUser}
                  visibleCol={{
                    ...visibleCol,
                    createdBy: false,
                    createdOn: false,
                  }}
                  dateFormat={dateFormat}
                  enabledViewUserGroup={
                    enabledViewUserGroup
                  }
                  enabledAddUserGroup={enabledAddUserGroup}
                  enabledDeleteUserGroup={
                    enabledDeleteUserGroup
                  }
                  enabledActivateUserGroup={
                    enabledActivateUserGroup
                  }
                  enabledAuditLog={false}
                  enabledActivityLog={enabledActivityLog}
                />
              </div>
            )}

          {userTabName === "Assigned_Roles" &&
            enabledViewUserRole && (
              <div
                style={{
                  height: `calc(100% - ${backArrowHeight} - ${tabHeight})`,
                }}
              >
                <UserAssignedRoles
                  token={token}
                  configResult={configResult}
                  applications={applications}
                  roles={roles}
                  selectedUser={selectedUser}
                  visibleCol={visibleCol}
                  dateFormat={dateFormat}
                  enabledViewUserRole={enabledViewUserRole}
                  enabledAddUserRole={enabledAddUserRole}
                  enabledDeleteUserRole={
                    enabledDeleteUserRole
                  }
                  enabledActivateUserRole={
                    enabledActivateUserRole
                  }
                  enabledAuditLog={false}
                  enabledActivityLog={enabledActivityLog}
                  loggedInUser={{
                    userId: "P000429",
                    emailId: "aastik.mandil@incture.com",
                    pid: "P000429",
                  }}
                />
              </div>
            )}

          {userTabName === "Features" &&
            (enabledViewUserDefaultFeature ||
              enabledViewUserAdditionalFeature) && (
              <div
                value={userTabName}
                style={{
                  height: `calc(100% - ${backArrowHeight} - ${tabHeight})`,
                }}
              >
                <div
                  className={`${classes.headerContainer} iagScroll`}
                >
                  {userFeatureTabDetails?.map(
                    (userFeatureTabDetail, i) => (
                      <Typography
                        key={i}
                        className={`${classes.headerItem} ${
                          userFeatureTabName ===
                            userFeatureTabDetail?.id &&
                          classes.headerItemSelected
                        }`}
                        onClick={() =>
                          setUserFeatureTabName(
                            userFeatureTabDetail?.id
                          )
                        }
                      >
                        {userFeatureTabDetail?.title}
                      </Typography>
                    )
                  )}
                </div>

                {userFeatureTabName ===
                  "Default_Features" &&
                  enabledViewUserDefaultFeature && (
                    <div
                      style={{
                        height: `calc(100% - ${tabHeight})`,
                      }}
                    >
                      <UserDefaultFeatures
                        token={token}
                        configResult={configResult}
                        applications={applications}
                        selectedUser={selectedUser}
                        visibleCol={visibleCol}
                        dateFormat={dateFormat}
                        enabledViewUserDefaultFeature={
                          enabledViewUserDefaultFeature
                        }
                        enabledDeleteUserDefaultFeature={
                          enabledDeleteUserDefaultFeature
                        }
                        enabledActivityLog={
                          enabledActivityLog
                        }
                      />
                    </div>
                  )}

                {userFeatureTabName ===
                  "Exceptional_Features" &&
                  enabledViewUserAdditionalFeature && (
                    <div
                      style={{
                        height: `calc(100% - ${tabHeight})`,
                      }}
                    >
                      <UserAdditionalFeatures
                        token={token}
                        configResult={configResult}
                        applications={applications}
                        selectedUser={selectedUser}
                        visibleCol={visibleCol}
                        dateFormat={dateFormat}
                        enabledViewUserAdditionalFeature={
                          enabledViewUserAdditionalFeature
                        }
                        enabledAddUserAdditionalFeature={
                          enabledAddUserAdditionalFeature
                        }
                        enabledDeleteUserAdditionalFeature={
                          enabledDeleteUserAdditionalFeature
                        }
                        enabledDeleteUserDefaultFeature={
                          enabledDeleteUserDefaultFeature
                        }
                        enabledActivityLog={
                          enabledActivityLog
                        }
                      />
                    </div>
                  )}
              </div>
            )}

          {userTabName === "Assigned_Template_Roles" &&
            enabledViewUserAssignedTemplateRole && (
              <div
                style={{
                  height: `calc(100% - ${backArrowHeight} - ${tabHeight})`,
                }}
              >
                <UserAssignedTemplateRole
                  token={token}
                  configResult={configResult}
                  roles={roles}
                  selectedUser={selectedUser}
                  templates={templates}
                  visibleCol={visibleCol}
                  dateFormat={dateFormat}
                  enabledViewUserAssignedTemplateRole={
                    enabledViewUserAssignedTemplateRole
                  }
                  enabledAddUserAssignedTemplateRole={
                    enabledAddUserAssignedTemplateRole
                  }
                  enabledUpdateUserAssignedTemplateRole={
                    enabledUpdateUserAssignedTemplateRole
                  }
                  enabledDeleteUserAssignedTemplateRole={
                    enabledDeleteUserAssignedTemplateRole
                  }
                  enabledActivateUserAssignedTemplateRole={
                    enabledActivateUserAssignedTemplateRole
                  }
                  enabledAuditLog={false}
                  enabledActivityLog={enabledActivityLog}
                />
              </div>
            )}

          {userTabName === "Activity_Log" &&
            enabledActivityLog && (
              <div
                style={{
                  height: `calc(100% - ${backArrowHeight} - ${tabHeight})`,
                }}
              >
                <UserActivityLog
                  token={token}
                  configResult={configResult}
                  selectedUser={selectedUser}
                  dateFormat={dateFormat}
                  enabledActivityLog={enabledActivityLog}
                />
              </div>
            )}
        </>
      ) : (
        <Users
          token={token}
          configResult={configResult}
          title={title}
          systems={systems}
          users={users}
          setusers={setusers}
          setFilteredData={setFilteredData}
          applications={applications}
          roles={roles}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          visibleCol={visibleCol}
          dateFormat={dateFormat}
          getUsers={getUsers}
          enabledWorkorg={enabledWorkorg}
          enabledExport={enabledExport}
          enabledAuditLog={enabledAuditLog}
          enabledActivityLog={enabledActivityLog}
          enabledViewUser={enabledViewUser}
          enabledAddNewUser={enabledAddNewUser}
          enabledImportSapUser={enabledImportSapUser}
          enabledImportAzureUser={enabledImportAzureUser}
          enabledUpdateUser={enabledUpdateUser}
          enabledDeleteUser={enabledDeleteUser}
          enabledActivateUser={enabledActivateUser}
          loggedInUser={loggedInUser}
          createUserFn={createUserFn}
          deleteUserFn={deleteUserFn}
          deleteMultipleUserFn={deleteMultipleUserFn}
        />
      )}
    </>
  );
};

const RolePage = ({
  token,
  configResult,
  appId,
  title,
  applications,
  roles,
  setroles,
  roleTemplates,
  setRoleTemplates,
  users,
  modules,
  features,
  apis,
  templates,
  visibleCol,
  dateFormat,
  getRoles,
  getRoleTemplates,
  roleTabDetails,
  enabledExport,
  enabledImportRole,
  enabledAuditLog,
  enabledActivityLog,
  enabledViewRole,
  enabledAddRole,
  enabledUpdateRole,
  enabledDeleteRole,
  enabledActivateRole,
  enabledViewAssociatedRole,
  enabledAddAssociatedRole,
  enabledDeleteAssociatedRole,
  enabledActivateAssociatedRole,
  enabledViewRoleAssignedUser,
  enabledAddRoleAssignedUser,
  enabledDeleteRoleAssignedUser,
  enabledActivateRoleAssignedUser,
  enabledViewRoleAssignedFeatures,
  enabledAddRoleAssignedFeatures,
  enabledDeleteRoleAssignedFeatures,
  enabledActivateRoleAssignedFeatures,
  enabledViewRoleAssignedApis,
  enabledAddRoleAssignedApis,
  enabledDeleteRoleAssignedApis,
  enabledActivateRoleAssignedApis,
  enabledViewRoleAssignedTemplateUser,
  enabledAddRoleAssignedTemplateUser,
  enabledUpdateRoleAssignedTemplateUser,
  enabledDeleteRoleAssignedTemplateUser,
  enabledActivateRoleAssignedTemplateUser,
  enabledViewRoleAssociatedScopes,
}) => {
  const classes = useStyle();
  const [selectedRole, setSelectedRole] = useState(null);
  const [isComposite, setIsComposite] = useState(false);
  const [roleTabName, setRoleTabName] = useState(
    roleTabDetails?.[0]?.id
  );
  const [roleDet, setRoleDet] = useState(null);

  useEffect(() => {
    setRoleTabName(roleTabDetails?.[0]?.id);
  }, [selectedRole]);

  useEffect(() => {
    if (selectedRole) {
      const role = roles?.find(
        (r) => Number(r?.id) === Number(selectedRole)
      );
      setRoleDet(role);
      setIsComposite(
        role?.isComposite === 1 ? true : false
      );
    }
  }, [selectedRole]);

  return (
    <>
      {selectedRole ? (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              height: backArrowHeight,
            }}
          >
            <IconButton
              size="small"
              onClick={() => {
                setSelectedRole(null);
                getRoles();
              }}
            >
              <BiArrowBack />
            </IconButton>
          </div>

          <div
            className={`${classes.headerContainer} iagScroll`}
          >
            {roleTabDetails
              ?.filter((roleTabDetail) =>
                isComposite
                  ? roleTabDetail?.id !==
                    "Associated_Scopes"
                  : roleTabDetail?.id !== "Associated_Role"
              )
              ?.map((roleTabDetail, index) => (
                <Typography
                  key={index}
                  className={`${classes.headerItem} ${
                    roleTabName === roleTabDetail?.id &&
                    classes.headerItemSelected
                  }`}
                  onClick={() =>
                    setRoleTabName(roleTabDetail?.id)
                  }
                >
                  {roleTabDetail?.title}
                </Typography>
              ))}
          </div>

          {roleTabName === "Basic_Info" && (
            <div
              style={{
                height: `calc(100% - ${backArrowHeight} - ${tabHeight})`,
              }}
            >
              <div
                style={{
                  position: "relative",
                  height: "100%",
                }}
              >
                <BasicRoleInfo
                  token={token}
                  configResult={configResult}
                  applications={applications}
                  roles={roles}
                  selectedRole={selectedRole}
                  visibleCol={visibleCol}
                  dateFormat={dateFormat}
                  enabledUpdateRole={enabledUpdateRole}
                  enabledActivityLog={enabledActivityLog}
                />
              </div>
            </div>
          )}

          {roleTabName === "Associated_Role" &&
            enabledViewAssociatedRole &&
            isComposite && (
              <div
                style={{
                  height: `calc(100% - ${backArrowHeight} - ${tabHeight})`,
                }}
              >
                <RoleAssociateRoles
                  token={token}
                  configResult={configResult}
                  roles={roles}
                  selectedRole={selectedRole}
                  visibleCol={visibleCol}
                  dateFormat={dateFormat}
                  enabledViewAssociatedRole={
                    enabledViewAssociatedRole
                  }
                  enabledAddAssociatedRole={
                    enabledAddAssociatedRole
                  }
                  enabledDeleteAssociatedRole={
                    enabledDeleteAssociatedRole
                  }
                  enabledActivateAssociatedRole={
                    enabledActivateAssociatedRole
                  }
                  enabledAuditLog={false}
                  enabledActivityLog={enabledActivityLog}
                />
              </div>
            )}

          {roleTabName === "Assigned_Users" &&
            enabledViewRoleAssignedUser && (
              <div
                style={{
                  height: `calc(100% - ${backArrowHeight} - ${tabHeight})`,
                }}
              >
                <RoleAssignedUsers
                  token={token}
                  configResult={configResult}
                  users={users}
                  selectedRole={selectedRole}
                  visibleCol={visibleCol}
                  dateFormat={dateFormat}
                  enabledViewRoleAssignedUser={
                    enabledViewRoleAssignedUser
                  }
                  enabledAddRoleAssignedUser={
                    enabledAddRoleAssignedUser
                  }
                  enabledDeleteRoleAssignedUser={
                    enabledDeleteRoleAssignedUser
                  }
                  enabledActivateRoleAssignedUser={
                    enabledActivateRoleAssignedUser
                  }
                  enabledAuditLog={false}
                  enabledActivityLog={enabledActivityLog}
                  loggedInUser={{
                    userId: "P000429",
                    emailId: "aastik.mandil@incture.com",
                    pid: "P000429",
                  }}
                />
              </div>
            )}

          {roleTabName === "Assigned_Features" &&
            enabledViewRoleAssignedFeatures && (
              <div
                style={{
                  height: `calc(100% - ${backArrowHeight} - ${tabHeight})`,
                }}
              >
                <RoleAssignedFeatures
                  token={token}
                  configResult={configResult}
                  entities={modules}
                  activities={features}
                  roles={roles}
                  selectedRole={selectedRole}
                  visibleCol={visibleCol}
                  dateFormat={dateFormat}
                  enabledViewRoleAssignedFeatures={
                    enabledViewRoleAssignedFeatures
                  }
                  enabledAddRoleAssignedFeatures={
                    enabledAddRoleAssignedFeatures
                  }
                  enabledDeleteRoleAssignedFeatures={
                    enabledDeleteRoleAssignedFeatures
                  }
                  enabledActivateRoleAssignedFeatures={
                    enabledActivateRoleAssignedFeatures
                  }
                  enabledAuditLog={false}
                  enabledActivityLog={enabledActivityLog}
                />
              </div>
            )}

          {roleTabName === "Assigned_Apis" &&
            enabledViewRoleAssignedApis && (
              <div
                style={{
                  height: `calc(100% - ${backArrowHeight} - ${tabHeight})`,
                }}
              >
                <RoleAssignedApis
                  token={token}
                  configResult={configResult}
                  apis={apis}
                  roles={roles}
                  selectedRole={selectedRole}
                  visibleCol={visibleCol}
                  dateFormat={dateFormat}
                  enabledViewRoleAssignedApis={
                    enabledViewRoleAssignedApis
                  }
                  enabledAddRoleAssignedApis={
                    enabledAddRoleAssignedApis
                  }
                  enabledDeleteRoleAssignedApis={
                    enabledDeleteRoleAssignedApis
                  }
                  enabledActivateRoleAssignedApis={
                    enabledActivateRoleAssignedApis
                  }
                  enabledAuditLog={false}
                  enabledActivityLog={enabledActivityLog}
                />
              </div>
            )}

          {roleTabName === "Assigned_Template_User" &&
            enabledViewRoleAssignedTemplateUser && (
              <div
                style={{
                  height: `calc(100% - ${backArrowHeight} - ${tabHeight})`,
                }}
              >
                <RoleAssignedTemplateUser
                  token={token}
                  configResult={configResult}
                  users={users}
                  selectedRole={selectedRole}
                  templates={templates}
                  visibleCol={visibleCol}
                  dateFormat={dateFormat}
                  enabledViewRoleAssignedTemplateUser={
                    enabledViewRoleAssignedTemplateUser
                  }
                  enabledAddRoleAssignedTemplateUser={
                    enabledAddRoleAssignedTemplateUser
                  }
                  enabledUpdateRoleAssignedTemplateUser={
                    enabledUpdateRoleAssignedTemplateUser
                  }
                  enabledDeleteRoleAssignedTemplateUser={
                    enabledDeleteRoleAssignedTemplateUser
                  }
                  enabledActivateRoleAssignedTemplateUser={
                    enabledActivateRoleAssignedTemplateUser
                  }
                  enabledAuditLog={false}
                  enabledActivityLog={enabledActivityLog}
                />
              </div>
            )}

          {roleTabName === "Associated_Scopes" &&
            enabledViewRoleAssociatedScopes && (
              <div
                style={{
                  height: `calc(100% - ${backArrowHeight} - ${tabHeight})`,
                }}
              >
                <RoleAssociatedScopes
                  token={token}
                  configResult={configResult}
                  selectedRole={selectedRole}
                  enabledViewRoleAssociatedScopes={
                    enabledViewRoleAssociatedScopes
                  }
                  appId={appId}
                />
              </div>
            )}
        </>
      ) : (
        <Roles
          token={token}
          configResult={configResult}
          title={title}
          applications={applications}
          roles={roles}
          setroles={setroles}
          roleTemplates={roleTemplates}
          setRoleTemplates={setRoleTemplates}
          selectedRole={selectedRole}
          setSelectedRole={setSelectedRole}
          visibleCol={visibleCol}
          dateFormat={dateFormat}
          getRoles={getRoles}
          getRoleTemplates={getRoleTemplates}
          enabledExport={enabledExport}
          enabledImportRole={enabledImportRole}
          enabledViewRole={enabledViewRole}
          enabledAddRole={enabledAddRole}
          enabledUpdateRole={enabledUpdateRole}
          enabledDeleteRole={enabledDeleteRole}
          enabledActivateRole={enabledActivateRole}
          enabledAuditLog={enabledAuditLog}
          enabledActivityLog={enabledActivityLog}
        />
      )}
    </>
  );
};

const GroupPage = ({
  token,
  configResult,
  title,
  groups,
  setGroups,
  applications,
  users,
  roles,
  visibleCol,
  dateFormat,
  getGroups,
  groupTabDetails,
  enabledAuditLog,
  enabledActivityLog,
  enabledViewGroup,
  enabledAddGroup,
  enabledUpdateGroup,
  enabledDeleteGroup,
  enabledActivateGroup,
  enabledViewGroupAssociatedUser,
  enabledAddGroupAssociatedUser,
  enabledDeleteGroupAssociatedUser,
  enabledActivateGroupAssociatedUser,
  enabledViewGroupAssignedRole,
  enabledAddGroupAssignedRole,
  enabledDeleteGroupAssignedRole,
  enabledActivateGroupAssignedRole,
}) => {
  const classes = useStyle();
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [groupTabName, setGroupTabName] = useState(
    groupTabDetails?.[0]?.id
  );

  useEffect(() => {
    setGroupTabName(groupTabDetails?.[0]?.id);
  }, [selectedGroup]);

  return (
    <>
      {selectedGroup ? (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              height: backArrowHeight,
            }}
          >
            <IconButton
              size="small"
              onClick={() => {
                setSelectedGroup(null);
                getGroups();
              }}
            >
              <BiArrowBack />
            </IconButton>
          </div>

          <div
            className={`${classes.headerContainer} iagScroll`}
          >
            {groupTabDetails?.map(
              (groupTabDetail, index) => (
                <Typography
                  key={index}
                  className={`${classes.headerItem} ${
                    groupTabName === groupTabDetail?.id &&
                    classes.headerItemSelected
                  }`}
                  onClick={() =>
                    setGroupTabName(groupTabDetail?.id)
                  }
                >
                  {groupTabDetail?.title}
                </Typography>
              )
            )}
          </div>

          {groupTabName === "Basic_Info" && (
            <div
              style={{
                height: `calc(100% - ${backArrowHeight} - ${tabHeight})`,
              }}
            >
              <div
                style={{
                  position: "relative",
                  height: "100%",
                }}
              >
                <BasicGroupInfo
                  token={token}
                  configResult={configResult}
                  applications={applications}
                  groups={groups}
                  setGroups={setGroups}
                  selectedGroup={selectedGroup}
                  visibleCol={{
                    ...visibleCol,
                    createdBy: false,
                    createdOn: false,
                  }}
                  dateFormat={dateFormat}
                  enabledUpdateGroup={enabledUpdateGroup}
                  enabledActivityLog={enabledActivityLog}
                />
              </div>
            </div>
          )}

          {groupTabName === "Associated_User" &&
            enabledViewGroupAssociatedUser && (
              <div
                style={{
                  height: `calc(100% - ${backArrowHeight} - ${tabHeight})`,
                }}
              >
                <GroupAssociatedUsers
                  token={token}
                  configResult={configResult}
                  roles={roles}
                  users={users}
                  groups={groups}
                  selectedGroup={selectedGroup}
                  visibleCol={{
                    ...visibleCol,
                    createdBy: false,
                    createdOn: false,
                    updatedBy: false,
                    updatedOn: false,
                  }}
                  dateFormat={dateFormat}
                  enabledViewGroupAssociatedUser={
                    enabledViewGroupAssociatedUser
                  }
                  enabledAddGroupAssociatedUser={
                    enabledAddGroupAssociatedUser
                  }
                  enabledDeleteGroupAssociatedUser={
                    enabledDeleteGroupAssociatedUser
                  }
                  enabledActivateGroupAssociatedUser={
                    enabledActivateGroupAssociatedUser
                  }
                  enabledAuditLog={false}
                  enabledActivityLog={enabledActivityLog}
                />
              </div>
            )}

          {groupTabName === "Assigned_Role" &&
            enabledViewGroupAssignedRole && (
              <div
                style={{
                  height: `calc(100% - ${backArrowHeight} - ${tabHeight})`,
                }}
              >
                <GroupAssignedRoles
                  token={token}
                  configResult={configResult}
                  roles={roles}
                  users={users}
                  groups={groups}
                  applications={applications}
                  selectedGroup={selectedGroup}
                  visibleCol={{
                    ...visibleCol,
                    createdBy: false,
                    createdOn: false,
                    updatedBy: false,
                    updatedOn: false,
                  }}
                  dateFormat={dateFormat}
                  enabledViewGroupAssignedRole={
                    enabledViewGroupAssignedRole
                  }
                  enabledAddGroupAssignedRole={
                    enabledAddGroupAssignedRole
                  }
                  enabledDeleteGroupAssignedRole={
                    enabledDeleteGroupAssignedRole
                  }
                  enabledActivateGroupAssignedRole={
                    enabledActivateGroupAssignedRole
                  }
                  enabledAuditLog={false}
                  enabledActivityLog={enabledActivityLog}
                />
              </div>
            )}
        </>
      ) : (
        <Groups
          token={token}
          configResult={configResult}
          title={title}
          users={users}
          groups={groups}
          selectedGroup={selectedGroup}
          setSelectedGroup={setSelectedGroup}
          applications={applications}
          visibleCol={{
            ...visibleCol,
            createdBy: false,
            createdOn: false,
          }}
          dateFormat={dateFormat}
          getGroups={getGroups}
          enabledViewGroup={enabledViewGroup}
          enabledAddGroup={enabledAddGroup}
          enabledDeleteGroup={enabledDeleteGroup}
          enabledActivateGroup={enabledActivateGroup}
          enabledAuditLog={enabledAuditLog}
          enabledActivityLog={enabledActivityLog}
        />
      )}
    </>
  );
};

const SystemPage = ({
  token,
  configResult,
  title,
  activityTabDetails,
  applications,
  setapplications,
  users,
  modules,
  setmodules,
  features,
  setfeatures,
  apis,
  payloads,
  visibleCol,
  dateFormat,
  getApplications,
  getModules,
  getFeatures,
  getApis,
  getPayloads,
  applicationTabDetails,
  enabledViewSystem,
  enabledAddSystem,
  enabledUpdateSystem,
  enabledDeleteSystem,
  enabledViewModule,
  enabledAddModule,
  enabledUpdateModule,
  enabledDeleteModule,
  enabledViewFeature,
  enabledAddFeature,
  enabledUpdateFeature,
  enabledDeleteFeature,
  enabledViewApi,
  enabledAddApi,
  enabledUpdateApi,
  enabledDeleteApi,
  enabledViewPayload,
  enabledAddPayload,
  enabledUpdatePayload,
  enabledDeletePayload,
  enabledActivityLog,
  enableSyncFeature,
  enabledViewActivityAssociatedApis,
  enabledAddActivityAssociatedApis,
  enabledDeleteActivityAssociatedApis,
  enabledActivateActivityAssociatedApis,
  enabledAuditLog,
}) => {
  const classes = useStyle();
  const [selectedApplication, setSelectedApplication] =
    useState(null);
  const [selectedEntity, setSelectedEntity] =
    useState(null);
  const [selectedActivity, setSelectedActivity] =
    useState(null);
  const [selectedApi, setSelectedApi] = useState(null);
  const [applicationTabName, setApplicationTabName] =
    useState(applicationTabDetails?.[0]?.id);
  const [activityTabName, setActivityTabName] = useState(
    activityTabDetails?.[0]?.id
  );

  useEffect(() => {
    setApplicationTabName(applicationTabDetails?.[0]?.id);
    setActivityTabName(activityTabDetails?.[0]?.id);
  }, [selectedApplication]);

  return (
    <>
      {selectedApplication ? (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              height: backArrowHeight,
            }}
          >
            <IconButton
              size="small"
              onClick={() => setSelectedApplication(null)}
            >
              <BiArrowBack />
            </IconButton>
          </div>

          <div
            className={`${classes.headerContainer} iagScroll`}
          >
            {applicationTabDetails?.map(
              (applicationTabDetail, index) => (
                <Typography
                  key={index}
                  className={`${classes.headerItem} ${
                    applicationTabName ===
                      applicationTabDetail?.id &&
                    classes.headerItemSelected
                  }`}
                  onClick={() =>
                    setApplicationTabName(
                      applicationTabDetail?.id
                    )
                  }
                >
                  {applicationTabDetail?.title}
                </Typography>
              )
            )}
          </div>

          {applicationTabName === "Basic_Info" && (
            <div
              style={{
                height: `calc(100% - ${backArrowHeight} - ${tabHeight})`,
              }}
            >
              <div
                style={{
                  position: "relative",
                  height: "100%",
                }}
              >
                <BasicApplicationInfo
                  token={token}
                  configResult={configResult}
                  selectedApplication={selectedApplication}
                  visibleCol={visibleCol}
                  dateFormat={dateFormat}
                />
              </div>
            </div>
          )}

          {applicationTabName === "Modules" && (
            <div
              style={{
                height: `calc(100% - ${backArrowHeight} - ${tabHeight})`,
              }}
            >
              <Grid container style={{ height: "100%" }}>
                <Grid
                  item
                  xs={
                    selectedActivity
                      ? 3
                      : selectedEntity
                      ? 6
                      : 12
                  }
                  style={{ height: "100%" }}
                >
                  <Modules
                    token={token}
                    configResult={configResult}
                    title={"Modules"}
                    entities={modules}
                    setmodules={setmodules}
                    selectedApplication={
                      selectedApplication
                    }
                    selectedEntity={selectedEntity}
                    setSelectedEntity={setSelectedEntity}
                    // visibleCol={visibleCol}
                    // dateFormat={dateFormat}
                    getModules={getModules}
                    getFeatures={getFeatures}
                    enabledViewModule={enabledViewModule}
                    enabledAddModule={enabledAddModule}
                    enabledUpdateModule={
                      enabledUpdateModule
                    }
                    enabledDeleteModule={
                      enabledDeleteModule
                    }
                    enabledActivityLog={enabledActivityLog}
                    enableSyncFeature={enableSyncFeature}
                  />
                </Grid>

                {selectedEntity && (
                  <Grid
                    item
                    xs={selectedActivity ? 3 : 6}
                    style={{ height: "100%" }}
                  >
                    <Features
                      token={token}
                      configResult={configResult}
                      title={"Features"}
                      activities={features}
                      setactivities={setfeatures}
                      selectedApplication={
                        selectedApplication
                      }
                      selectedEntity={selectedEntity}
                      selectedActivity={selectedActivity}
                      setSelectedActivity={
                        setSelectedActivity
                      }
                      // visibleCol={visibleCol}
                      // dateFormat={dateFormat}
                      getFeatures={getFeatures}
                      enabledViewFeature={
                        enabledViewFeature
                      }
                      enabledAddFeature={enabledAddFeature}
                      enabledUpdateFeature={
                        enabledUpdateFeature
                      }
                      enabledDeleteFeature={
                        enabledDeleteFeature
                      }
                      enabledActivityLog={
                        enabledActivityLog
                      }
                    />
                  </Grid>
                )}

                {selectedActivity && (
                  <Grid
                    item
                    xs={6}
                    style={{ height: "100%" }}
                  >
                    <div
                      className={`${classes.applicationDetailHeaderContainer} iagScroll`}
                    >
                      {activityTabDetails?.map(
                        (activityTabDetail, index) => (
                          <Typography
                            key={index}
                            className={`${
                              classes.activityDetailHeaderItem
                            } ${
                              activityTabName ===
                                activityTabDetail?.id &&
                              classes.activityDetailHeaderItemSelected
                            }`}
                            onClick={() =>
                              setActivityTabName(
                                activityTabDetail?.id
                              )
                            }
                          >
                            {activityTabDetail?.title}
                          </Typography>
                        )
                      )}
                    </div>

                    {activityTabName ===
                      "Associated_Api" && (
                      <div
                        className="iwaScroll"
                        style={{
                          height: `calc(100% - ${tabHeight})`,
                        }}
                      >
                        <ActivityAssociatedApis
                          token={token}
                          configResult={configResult}
                          apis={apis}
                          activities={features}
                          selectedApplication={
                            selectedApplication
                          }
                          selectedActivity={
                            selectedActivity
                          }
                          visibleCol={visibleCol}
                          dateFormat={dateFormat}
                          enabledViewActivityAssociatedApis={
                            enabledViewActivityAssociatedApis
                          }
                          enabledAddActivityAssociatedApis={
                            enabledAddActivityAssociatedApis
                          }
                          enabledDeleteActivityAssociatedApis={
                            enabledDeleteActivityAssociatedApis
                          }
                          enabledActivateActivityAssociatedApis={
                            enabledActivateActivityAssociatedApis
                          }
                          enabledAuditLog={enabledAuditLog}
                          enabledActivityLog={
                            enabledActivityLog
                          }
                        />
                      </div>
                    )}
                  </Grid>
                )}
              </Grid>
            </div>
          )}

          {applicationTabName === "Apis" && (
            <div
              style={{
                height: `calc(100% - ${backArrowHeight} - ${tabHeight})`,
              }}
            >
              <Grid container style={{ height: "100%" }}>
                <Grid
                  item
                  xs={selectedEntity ? 6 : 12}
                  style={{ height: "100%" }}
                >
                  <Apis
                    token={token}
                    configResult={configResult}
                    title={"Apis"}
                    apis={apis}
                    getApis={getApis}
                    selectedApi={selectedApi}
                    setSelectedApi={setSelectedApi}
                    selectedApplication={
                      selectedApplication
                    }
                    // visibleCol={visibleCol}
                    // dateFormat={dateFormat}
                    enabledViewApi={enabledViewApi}
                    enabledAddApi={enabledAddApi}
                    enabledUpdateApi={enabledUpdateApi}
                    enabledDeleteApi={enabledDeleteApi}
                  />
                </Grid>

                {selectedApi && (
                  <Grid
                    item
                    xs={6}
                    style={{ height: "100%" }}
                  >
                    <Payloads
                      token={token}
                      configResult={configResult}
                      title={"Payloads"}
                      payloads={payloads}
                      selectedApi={selectedApi}
                      getPayloads={getPayloads}
                      // visibleCol={visibleCol}
                      // dateFormat={dateFormat}
                      enabledViewPayload={
                        enabledViewPayload
                      }
                      enabledAddPayload={enabledAddPayload}
                      enabledUpdatePayload={
                        enabledUpdatePayload
                      }
                      enabledDeletePayload={
                        enabledDeletePayload
                      }
                    />
                  </Grid>
                )}
              </Grid>
            </div>
          )}

          {applicationTabName === "Users" && (
            <div
              style={{
                height: `calc(100% - ${backArrowHeight} - ${tabHeight})`,
              }}
            >
              <UserApplicationAccess
                token={token}
                configResult={configResult}
                selectedApplication={selectedApplication}
                enabledViewApplicationAccessUser={true}
                visibleCol={visibleCol}
                dateFormat={dateFormat}
              />
            </div>
          )}
        </>
      ) : (
        <Systems
          token={token}
          configResult={configResult}
          title={title}
          applications={applications}
          setapplications={setapplications}
          users={users}
          setSelectedApplication={setSelectedApplication}
          getApplications={getApplications}
          enabledViewSystem={enabledViewSystem}
          enabledAddSystem={enabledAddSystem}
          enabledUpdateSystem={enabledUpdateSystem}
          enabledDeleteSystem={enabledDeleteSystem}
          getModules={getModules}
          getFeatures={getFeatures}
          enabledActivityLog={enabledActivityLog}
          enableSyncFeature={enableSyncFeature}
        />
      )}
    </>
  );
};

export default Index;
