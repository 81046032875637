import React, { useState, useEffect } from "react";
import { Typography, Popper, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { HiDatabase, HiOutlineDatabase } from "react-icons/hi";
// import { FaRegUserCircle, FaUserCircle } from "react-icons/fa";
import { FiHelpCircle } from "react-icons/fi";
import { GrApps } from "react-icons/gr";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const useStyle = makeStyles((theme) => ({
  sidebarItemContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    padding: theme.spacing(1, 0),
    position: "relative",
    cursor: "pointer",
    overflow: "overlay",
    color: theme.palette.text.primary,
    backgroundColor: (selected) => (selected ? "#F1F5FE" : "white"),
    borderRadius: 8,
    width: 72,
  },
  sidebarItemSelected: {
    color: theme.palette.primary.main,
  },
  subItem: {
    fontSize: 12,
    cursor: "pointer",
    width: 180,
    padding: 6,
    "&:hover": {
      background: theme.palette.action.hover,
      fontWeight: "bold",
    },
  },

  sidebarContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: 82,
    boxShadow: "0px 8px 24px rgba(192, 192, 192, 0.25)",
    height: "100%",
    background: theme.palette.background.paper,
    overflow: "hidden",
    padding: 8,
    transform: "rotate(0deg) !important",
  },
  sidebar: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  sidebarItemTitle: {
    fontSize: theme.spacing(1.5),
    textAlign: "center",
  },
  sidebarBottomContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  sidebarBottomContainerIcon: {
    padding: theme.spacing(0.5),
  },
}));

const SidebarItem = ({
  Icon,
  FilledIcon,
  title,
  selected,
  to,
  subItems,
  selectedSubItem,
  setSelectedSubItem,
}) => {
  // const userReducerState = useSelector(state => state.userReducer);
  // const userModules =
  //   Object?.keys(
  //     userReducerState?.entitiesAndActivities?.entitiesAndActivities?.[0] || {}
  //   ) || [];
  // console.log('userModule',userModules);
  const classes = useStyle(selected);
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <div
      aria-owns={Boolean(anchorEl) ? "mouse-over-popover" : undefined}
      onMouseLeave={() => {
        setAnchorEl(null);
      }}
    >
      <Link
        to={to}
        className={`${classes.sidebarItemContainer}`}
        onClick={() => {
          if (subItems?.length > 0) {
            setSelectedSubItem(subItems?.[0]?.uri);
          }
        }}
        onMouseEnter={(e) => {
          e.stopPropagation();
          setAnchorEl(e.currentTarget);
        }}
        // onMouseLeave={() => {
        //   setAnchorEl(null);
        // }}
      >
        {selected ? (
          <FilledIcon
            className={classes.sidebarItemSelected}
            style={{ fontSize: 20 }}
          />
        ) : (
          <Icon style={{ fontSize: 20 }} />
        )}

        <Typography
          className={`${classes.sidebarItemTitle} ${
            selected && classes.sidebarItemSelected
          }`}
        >
          {title}
        </Typography>
      </Link>

      {subItems && (
        <Popper
          id="mouse-over-popper"
          style={{ zIndex: 9999 }}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          placement="right-start"
          transition
          onClose={() => setAnchorEl(null)}
        >
          <Paper>
            {subItems?.map((subItem, index) => (
              <Link
                to={to}
                key={index}
                className={`${classes.sidebarSubItemContainer}`}
              >
                <Typography
                  color={
                    selectedSubItem === subItem?.uri ? "primary" : "initial"
                  }
                  key={subItem?.name}
                  className={classes.subItem}
                  onClick={(e) => {
                    setSelectedSubItem(subItem?.uri);
                    setAnchorEl(null);
                  }}
                >
                  {subItem?.name}
                </Typography>
              </Link>
            ))}
          </Paper>
        </Popper>
      )}
    </div>
  );
};

function Sidebar({ selectedSubItem, setSelectedSubItem }) {
  const userReducerState = useSelector((state) => state.userReducer);
  const classes = useStyle();
  const location = useLocation();
  const pathname = location.pathname.split("/");

  useEffect(() => {
    if (pathname?.[1] === "masterData") {
      setSelectedSubItem("Systems");
    } else if (pathname?.[1] === "profile") {
      setSelectedSubItem("Profile");
    } else {
      setSelectedSubItem("Systems");
    }
  }, []);

  return (
    <div className={classes.sidebarContainer}>
      <div className={classes.sidebar}>
        <SidebarItem
          Icon={HiOutlineDatabase}
          FilledIcon={HiDatabase}
          title="Master Data"
          selected={pathname?.[1] === "masterData"}
          to="/masterData"
          badgeValue={0}
          subItems={[
            { name: "Systems", uri: "Systems" },
            { name: "Groups", uri: "Groups" },
            { name: "Roles", uri: "Roles" },
            { name: "Users", uri: "Users" },
            {name: "Dashboard", uri: "Dashboard"},
          ]}
          selectedSubItem={selectedSubItem}
          setSelectedSubItem={setSelectedSubItem}
        />

        {/* <SidebarItem
          Icon={FaRegUserCircle}
          FilledIcon={FaUserCircle}
          title="Profile"
          selected={pathname?.[1] === "profile"}
          to="/profile"
          badgeValue={0}
        /> */}
      </div>

      <div className={classes.sidebarBottomContainer}>
        <div className={classes.sidebarBottomContainerIcon}>
          <FiHelpCircle style={{ fontSize: 20 }} />
        </div>

        <div className={classes.sidebarBottomContainerIcon}>
          <GrApps style={{ fontSize: 20 }} />
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
